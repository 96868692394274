import { FC, useCallback, useState } from 'react';
import { Box, Grid, Stack, Theme } from '@mui/material';
import FormSection from '@/components/form/form-section';
import { useQuery } from 'react-query';
import useQueryHelper from '@/hooks/use-query-helper';
import { QueryKey } from '@/lib/query-client';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import WorkosForm from './workos-form';
import { getAllIdentProviders, MAX_PROVIDER_COUNT } from '@/lib/services/ident.service';
import { getTypedIdentProvider } from '@/lib/models/ident-provider';
import { createUseStyles } from 'react-jss';
import IdentProviderIcon from '@/components/ident-provider-icon';
import Text from '@/components/text';
import Button from '@/components/button';
import classNames from 'classnames';
import ProviderForm from './provider-form';
import { IdentProviderModel } from '@/lib/models/ident-provider/ident-provider.model';
import Tooltip from '@/components/tooltip';

const useStyles = createUseStyles((theme: Theme) => ({
  listItem: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 8,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    '&:hover, &.active': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  providerFormCard: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: '8px 16px 0px 16px',
    borderRadius: theme.shape.borderRadius,
    minHeight: 580,
  },
  providerFormPlaceholder: {
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    height: 580,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const IdentForm: FC = () => {
  const { canChangeAuth } = useFeature();
  const [provider, setProvider] = useState<IdentProviderModel | null>(null);

  const styles = useStyles();
  const query = useQuery([QueryKey.IdentProviderList], async () => getAllIdentProviders());

  const { data: providers = [], refetch } = query;
  const { showLoader } = useQueryHelper(query);

  const handleLoadProvider = useCallback(
    (providerId: string) => {
      const provider = providers.find(({ id }) => id === providerId);
      setProvider(provider || null);
    },
    [providers]
  );

  const handleAddProvider = () => {
    setProvider(new IdentProviderModel({ enabled: false }));
  };

  const handleReload = (provider: IdentProviderModel) => {
    refetch();
    setProvider(provider);
  };

  const handleDeleteProvider = (providerId: string) => {
    refetch();

    const updated = providers.filter(({ id }) => id !== providerId);
    const nextProvider = updated.length ? updated[updated.length - 1] : null;
    setProvider(nextProvider);
  };

  if (showLoader) {
    return null;
  }

  const hasProvider = !!provider;
  const providerId = provider?.id;
  const hasMaxProviders =
    providers.filter(({ type }) => ['OIDC', 'SAML', 'SAML-XML', 'SAML-URL'].includes(type))
      .length >= MAX_PROVIDER_COUNT;
  const canAddProvider = canChangeAuth && !hasMaxProviders;

  return (
    <Box>
      <PageFeatureToast featureId="change-auth" can={canChangeAuth} />

      <FormSection title="Identity Providers">
        <>
          <Grid container marginBottom={4}>
            <Grid item xs={12}>
              Use the form below to manage identity providers and determine how users can
              authenticate to the SurePath AI network.
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Stack width="100%" gap={1}>
                {providers.map((provider) => {
                  const { id, name, type } = getTypedIdentProvider(provider);

                  return (
                    <Stack
                      direction="row"
                      gap={1}
                      alignItems="center"
                      key={`${id}-${type}`}
                      className={classNames(styles.listItem, { active: providerId === id })}
                      onClick={() => handleLoadProvider(id)}
                    >
                      <IdentProviderIcon providerType={type} />
                      <Text size="small" dotdot>
                        {name}
                      </Text>
                    </Stack>
                  );
                })}
                <Box mt={2} width="100%">
                  {canChangeAuth && (
                    <Tooltip
                      title={`The maximum number of allowed OIDC or SAML providers is ${MAX_PROVIDER_COUNT}. If you want to create a new provider, please delete one first.`}
                      disabled={canAddProvider}
                    >
                      <Button
                        label="Add Provider"
                        icon="plus"
                        fullWidth
                        onClick={handleAddProvider}
                        disabled={!canAddProvider}
                        size="small"
                      />
                    </Tooltip>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={9}>
              {hasProvider && (
                <Box className={styles.providerFormCard}>
                  <ProviderForm
                    provider={provider}
                    onDelete={handleDeleteProvider}
                    onChange={handleReload}
                  />
                </Box>
              )}
              {!hasProvider && (
                <Box className={styles.providerFormPlaceholder}>
                  <Box width={380} textAlign="center">
                    <Text color="grey">
                      Choose a provider from the list to the left, or click "Add Provider" to create
                      a new Identity Provider.
                    </Text>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </>
      </FormSection>
      <WorkosForm />
    </Box>
  );
};

export default IdentForm;
