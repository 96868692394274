import { object, string } from 'yup';
import { JsonObject } from '@/lib/helpers';
import { BaseModel, CreateBaseModel } from '@/lib/models/base.model';
import { PrivateModelConfig, PrivateModelType } from '.';

export class PrivateModelModel extends BaseModel {
  orgId: string;
  name: string;
  connectorId: string;
  provider: string;
  service: string;
  modelId: string; // <connector type>.<connector service>.<model identifier>
  modelProviderName: string;
  providerModelProperties: PrivateModelConfig;
  type: PrivateModelType;

  schema = object({
    name: string().required('Name is required'),
    connectorId: string().required('Please choose a connector'),
    modelId: string().required('Please choose a model'),
  });

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof PrivateModelModel, unknown>;

    this.type = (data.type as PrivateModelType) || 'aws';
    this.orgId = (data.orgId as string) || '';
    this.name = (data.name as string) || '';
    this.connectorId = (data.connectorId as string) || '';
    this.provider = (data.provider as string) || '';
    this.service = (data.service as string) || '';
    this.modelId = (data.modelId as string) || '';
    this.modelProviderName = (data.modelProviderName as string) || '';

    // support legacy config prop
    const modelProps = (data.providerModelProperties ||
      (data as JsonObject).config) as PrivateModelConfig;

    this.providerModelProperties = modelProps || {
      model: '',
      modelId: '',
    };
  }

  get iconId(): string {
    const modelToken = String(this.modelId).split('.').pop();
    return String(modelToken).split('-').shift() || '';
  }

  // extending model-type-specific classes should override this as-needed
  get typedProps(): JsonObject {
    return {};
  }
}

export class CreatePrivateModelModel extends CreateBaseModel {
  id: string;
  orgId: string;
  name: string;
  connectorId: string;
  provider: string;
  service: string;
  modelId: string; // <connector type>.<connector service>.<model identifier>
  providerModelProperties: PrivateModelConfig;
  typedProps: JsonObject;

  constructor(privateModel: PrivateModelModel) {
    super();

    this.id = privateModel.id || '';
    this.orgId = privateModel.orgId;
    this.name = privateModel.name;
    this.connectorId = privateModel.connectorId;

    this.provider = privateModel.provider;
    this.service = privateModel.service;
    this.modelId = privateModel.modelId;

    this.providerModelProperties = privateModel.providerModelProperties;

    this.typedProps = privateModel.typedProps;
  }

  get _props(): Record<string, unknown> {
    const props = {
      id: this.id,
      orgId: this.orgId,
      name: this.name,
      connectorId: this.connectorId,
      provider: this.provider,
      service: this.service,
      modelId: this.modelId,
      providerModelProperties: this.providerModelProperties,
      ...this.typedProps,
    };

    return props;
  }
}
