import { FC } from 'react';
import Icon from '../icon';
import { Box } from '@mui/material';
import { IdentProviderMap, IdentProviderType } from '@/lib/models/ident-provider';

interface Props {
  providerType: IdentProviderType;
}

const IdentProviderIcon: FC<Props> = ({ providerType }) => {
  const { iconSVG: IconEl, icon } = IdentProviderMap.get(providerType) || {};

  if (IconEl) {
    return <img src={IconEl} alt={providerType} width={30} height={25} />;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width={30} height={25}>
      <Icon name={icon || 'plug'} />
    </Box>
  );
};

export default IdentProviderIcon;
