import { FC } from 'react';
import Chip, { ChipColor } from './';
import { createUseStyles } from 'react-jss';

export type ChipStatus =
  | 'inactive'
  | 'pending'
  | 'active'
  | 'updating'
  | 'deleting'
  | 'error'
  | 'failed'
  | 'unknown'
  | 'enabled'
  | 'disabled'
  | 'yes'
  | 'no';

interface Props {
  value: ChipStatus;
  size?: 'x-small' | 'small' | 'medium';
  circle?: boolean;
}

const ColorMap: Map<ChipStatus, ChipColor> = new Map();
ColorMap.set('inactive', 'grey');
ColorMap.set('pending', 'warning');
ColorMap.set('active', 'success');
ColorMap.set('updating', 'warning');
ColorMap.set('deleting', 'warning');
ColorMap.set('error', 'error');
ColorMap.set('failed', 'error');
ColorMap.set('unknown', 'warning');
ColorMap.set('enabled', 'success');
ColorMap.set('disabled', 'grey');
ColorMap.set('yes', 'success');
ColorMap.set('no', 'error');

const useStyles = createUseStyles({
  circle: {
    width: 25,
  },
});

const StatusChip: FC<Props> = ({ value, size = 'medium', circle = false }) => {
  const styles = useStyles();
  return (
    <Chip
      label={circle ? '' : String(value).toUpperCase()}
      color={ColorMap.get(value) || 'primary'}
      size={size}
      className={circle ? styles.circle : ''}
    />
  );
};

export default StatusChip;
