import { Form } from '@/components/form';
import InputControl from '@/components/form/input-control';
import useFormHandle from '@/hooks/use-form-handle.hook';
import {
  getTypedIdentProvider,
  IdentProviderType,
  IdentProviderTypeOptions,
} from '@/lib/models/ident-provider';
import {
  CreateIdentProviderModel,
  IdentProviderModel,
} from '@/lib/models/ident-provider/ident-provider.model';
import { Box, Grid, Stack, Theme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import OidcIdentProviderForm from './oidc-ident-provider-form';
import FormButtons from '@/components/form/form-buttons';
import { createUseStyles } from 'react-jss';
import SamlIdentProviderForm from './saml-ident-provider-form';
import IdentProviderIcon from '@/components/ident-provider-icon';
import Text from '@/components/text';
import Select from '@/components/form/select';
import useToast from '@/hooks/use-toast.hook';
import {
  createIdentProvider,
  deleteIdentProvider,
  getIdentProviderById,
  patchIdentProvider,
} from '@/lib/services/ident.service';
import useFeature from '@/hooks/use-feature';
import InlineToast from '@/components/toasts/inline';
import { SamlIdentProviderModel } from '@/lib/models/ident-provider/saml-ident-provider.model';
import SamlUrlIdentProviderForm from './saml-url-ident-provider-form';
import { SamlMetadataUrlIdentProviderModel } from '@/lib/models/ident-provider/saml-metadata-url-ident-provider.model';
import SamlXmlIdentProviderForm from './saml-xml-ident-provider-form';

interface Props {
  provider: IdentProviderModel;
  onDelete: (providerId: string) => void;
  onChange: (provider: IdentProviderModel) => void;
}

const formModel = new IdentProviderModel();
const fields = ['name', 'type', 'enabled'];
const editFields = ['name', 'type', 'enabled', 'identityProviderConfig'];

const useStyles = createUseStyles((theme: Theme) => ({
  formButtons: {
    marginTop: 32,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  providerForm: {
    height: '100%',
    minHeight: 580,
  },
  providerFormHeader: {
    height: 34,
    marginBottom: 16,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
}));

const ProviderForm: FC<Props> = ({ provider, onDelete, onChange }) => {
  const styles = useStyles();
  const [providerType, setProviderType] = useState<IdentProviderType | ''>('');
  const [formError, setFormError] = useState('');
  const { toast, errorToast } = useToast();
  const { canChangeAuth } = useFeature();

  const isNew = !provider.id;

  const formHandle = useFormHandle({
    initialValues: getTypedIdentProvider(provider),
    validationSchema: formModel.schemaSlice(fields),
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setFormError('');
      if (isNew) {
        const { created, error, model } = await createIdentProvider(values);

        setSubmitting(false);
        resetForm({ values });

        if (created) {
          toast('The identity provider was created');
          onChange(model!);
          return;
        }

        setFormError(error || 'The identity provider was not created');
        return;
      }

      const { id, name, enabled, identityProviderConfig } = new CreateIdentProviderModel(values);

      const patchData = {
        name,
        enabled,
        identityProviderConfig,
      };

      const { patched, error } = await patchIdentProvider(id, patchData);

      setSubmitting(false);
      resetForm({ values });

      if (patched) {
        toast('The identity provider was updated');
        const updatedProvider = await getIdentProviderById(id);
        if (updatedProvider) {
          onChange(getTypedIdentProvider(updatedProvider));
        }
        return;
      }

      setFormError(error || 'The identity provider was not updated');
    },
  });

  const { loadData, values, setValues, updateValidationSchema } = formHandle;

  const canAdd = canChangeAuth && !!values.canWrite;
  const canDelete = canChangeAuth && !isNew && canAdd;
  const canDisable = !isNew && canChangeAuth;

  const handleDelete = () => {
    deleteIdentProvider(provider.id).then(({ deleted, error }) => {
      if (!deleted) {
        errorToast(error || 'The identity provider was not deleted');
        return;
      }

      toast('The identity provider was deleted');
      onDelete(provider.id);
    });
  };

  const handleUpdateValidation = useCallback(
    (model: IdentProviderModel) => {
      const basicValidation = isNew && !['SAML-XML', 'SAML-URL'].includes(model.type);
      updateValidationSchema(model.schemaSlice(basicValidation ? fields : editFields));
    },
    [isNew, updateValidationSchema]
  );

  const handleChangeProviderType = useCallback(
    (value: string) => {
      if (!isNew) {
        return;
      }

      const updatedType = value as IdentProviderType;
      setProviderType(updatedType);
      values.type = updatedType;
      setValues(getTypedIdentProvider(values));
      const typedModel = getTypedIdentProvider(values);
      handleUpdateValidation(typedModel);
    },
    [isNew, setValues, values, handleUpdateValidation]
  );

  const ProviderTypeOptions = useMemo(() => {
    return IdentProviderTypeOptions.filter(({ disabled }) => !disabled).map(({ value, label }) => {
      return {
        value,
        label: (
          <Stack direction="row" gap={1} alignItems="center">
            <IdentProviderIcon providerType={value as IdentProviderType} />
            <Text>{label}</Text>
          </Stack>
        ),
      };
    });
  }, []);

  useEffect(() => {
    const typedModel = getTypedIdentProvider(provider);
    loadData(typedModel);
    setProviderType(typedModel.type);
    handleUpdateValidation(typedModel);
    setFormError('');
  }, [provider, loadData, handleUpdateValidation]);

  const hasFormError = !!formError;

  return (
    <Form formHandle={formHandle} className={styles.providerForm}>
      <Stack height="100%" minHeight={580}>
        <Box flexGrow={1}>
          <Box className={styles.providerFormHeader}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" gap={1} alignItems="center">
                {!isNew && <IdentProviderIcon providerType={provider.type} />}
                <Text bold>{isNew ? 'Add New Identity Provider' : provider.name}</Text>
              </Stack>
              <Box>
                <InputControl
                  name="enabled"
                  label="Enabled"
                  type="switch"
                  formHandle={formHandle}
                  readonly={!canDisable}
                />
              </Box>
            </Stack>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputControl
                name="name"
                label="Provider Name"
                formHandle={formHandle}
                disabled={!isNew && !canAdd}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                name="type"
                label="Provider Type"
                options={ProviderTypeOptions}
                disabled={!isNew}
                value={providerType}
                onChange={handleChangeProviderType}
                required
              />
            </Grid>
          </Grid>

          <Box mt={2}>
            {!isNew && providerType === 'OIDC' && (
              <OidcIdentProviderForm formHandle={formHandle} provider={provider} />
            )}

            {providerType === 'SAML-URL' && (
              <Box mb={2}>
                <SamlUrlIdentProviderForm
                  formHandle={formHandle}
                  provider={provider as SamlMetadataUrlIdentProviderModel}
                />
              </Box>
            )}

            {providerType === 'SAML-XML' && (
              <Box mb={2}>
                <SamlXmlIdentProviderForm
                  formHandle={formHandle}
                  provider={provider as SamlMetadataUrlIdentProviderModel}
                />
              </Box>
            )}

            {!isNew && ['SAML', 'SAML-URL', 'SAML-XML'].includes(providerType) && (
              <SamlIdentProviderForm
                formHandle={formHandle}
                provider={provider as SamlIdentProviderModel}
              />
            )}
          </Box>
        </Box>
        {hasFormError && (
          <Box mt={2}>
            <InlineToast level="error" message={formError} />
          </Box>
        )}
        <Box className={styles.formButtons}>
          <FormButtons
            formHandle={formHandle}
            onDelete={canDelete ? handleDelete : undefined}
            deleteName={provider?.name}
            size="small"
          />
        </Box>
      </Stack>
    </Form>
  );
};

export default ProviderForm;
