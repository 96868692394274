import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FC } from 'react';
import InputControl from '@/components/form/input-control';
import { ViewState } from '@/lib/helpers';
import { Box } from '@mui/material';
import { ContextProviderModel } from '@/lib/models/context-provider/context-provider.model';
import { ConnectorModel } from '@/lib/models/connector/connector.model';

interface Props {
  provider: ContextProviderModel;
  formHandle: FormHandle<ContextProviderModel>;
  mode: ViewState;
  connector?: ConnectorModel | null;
}

const AwsKbContextProvider: FC<Props> = ({ formHandle, mode }) => {
  const inWriteMode = ['edit', 'add'].includes(mode);

  return (
    <>
      {inWriteMode && (
        <Box>
          <InputControl
            name="config.knowledgeBaseId"
            label="Knowledge Base Id"
            formHandle={formHandle}
            required
          />

          <InputControl name="config.knowledgeBaseName" label="Name" formHandle={formHandle} />
        </Box>
      )}
    </>
  );
};

export default AwsKbContextProvider;
