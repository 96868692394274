import axios from 'axios';
import { JsonObject } from '../helpers';
import Environment from '../environment';
import { AuthProvider } from './auth.service';
import {
  ApiResponse,
  CreateErrorHandler,
  CreateResponse,
  DeleteResponse,
  handleCreateErrorResponse,
  handleCreateResponse,
  handleDeleteErrorResponse,
  handleDeleteResponse,
  handlePatchErrorResponse,
  handlePatchResponse,
  handleResponse,
  handleUpdateErrorResponse,
  handleUpdateResponse,
  PatchErrorHandler,
  PatchResponse,
  UpdateErrorHandler,
  UpdateResponse,
} from '.';

const axiosClient = axios.create({
  baseURL: Environment.SP_API_URL,
  timeout: 10000,
  withCredentials: true,
  responseType: 'json',
  headers: {
    'X-Requested-By': 'surepath',
  },
});

axiosClient.interceptors.request.use((config) => {
  if (AuthProvider.token) {
    config.headers['Surepath-Authorization'] = `Bearer ${AuthProvider.token}`;
  }
  return config;
});

export const post = async (path: string, data: JsonObject): Promise<ApiResponse> => {
  return axiosClient
    .post(path, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(handleResponse);
};

export const create = async (
  path: string,
  data: JsonObject,
  onError: CreateErrorHandler = handleCreateErrorResponse
): Promise<CreateResponse> => {
  return axiosClient
    .post(path, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(handleCreateResponse)
    .catch(onError);
};

export const update = async (
  path: string,
  data: JsonObject,
  onError: UpdateErrorHandler = handleUpdateErrorResponse
): Promise<UpdateResponse> => {
  return axiosClient
    .put(path, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(handleUpdateResponse)
    .catch(onError);
};

export const patch = async (
  path: string,
  data: JsonObject,
  onError: PatchErrorHandler = handlePatchErrorResponse
): Promise<PatchResponse> => {
  return axiosClient
    .patch(path, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(handlePatchResponse)
    .catch(onError);
};

// unfortunately delete is a keyword
export const deleteApi = async (path: string, params?: JsonObject): Promise<DeleteResponse> => {
  return axiosClient
    .delete(path, { params })
    .then(handleDeleteResponse)
    .catch(handleDeleteErrorResponse);
};

export const get = async (
  path: string,
  params?: JsonObject | URLSearchParams
): Promise<ApiResponse> => {
  return axiosClient.get(path, { params }).then(handleResponse);
};
