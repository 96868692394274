import { Box, Stack, Theme } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import Tooltip from '../tooltip';
import Icon from '../icon';
import { SortButton } from './helpers';

const useStyles = createUseStyles((theme: Theme) => ({
  sortIcons: {
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    '& > div:first-child': {
      borderLeft: `1px solid ${theme.palette.grey[400]}`,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderRight: `1px solid ${theme.palette.grey[400]}`,
    },
    '& > div:last-child': {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
  sortButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover, &.active': {
      backgroundColor: '#fff',
    },
    '& .sort-tooltip': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 8,
    },
  },
}));

interface Props {
  onSort: (sortType: string) => void;
  sortType?: string;
  buttons: SortButton[];
}

const ButtonBar: FC<Props> = ({ onSort, sortType: activeSort, buttons }) => {
  const styles = useStyles();

  const handleSort = (sort: string) => {
    onSort(sort);
  };

  return (
    <Stack direction="row" className={styles.sortIcons}>
      {buttons.map(({ sortType, icon, tooltip }) => {
        return (
          <Box
            className={classNames(styles.sortButton, { active: activeSort === sortType })}
            onClick={() => handleSort(sortType)}
            key={sortType}
          >
            <Tooltip title={tooltip} className="sort-tooltip" disabled={!tooltip}>
              <Icon name={icon} />
            </Tooltip>
          </Box>
        );
      })}
    </Stack>
  );
};

export default ButtonBar;
