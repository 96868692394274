import { FC } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import Icon from '../icon';
import Text from '../text';
import { PrivateModelModel } from '@/lib/models/private-model/private-model.model';
import ModelIcon, { ModelIconId } from '../model-icon';

interface Props {
  model: PrivateModelModel;
  onInfoClick?: (model: PrivateModelModel) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  privateModelButton: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 8,
    borderRadius: theme.shape.borderRadius,
  },
}));

const PrivateModelButton: FC<Props> = ({ model, onInfoClick }) => {
  const styles = useStyles();
  const { iconId, name } = model;
  const hasIcon = !!iconId;

  const hasInfoButton = !!onInfoClick;

  const handleClick = () => {
    onInfoClick?.(model);
  };

  return (
    <Box className={styles.privateModelButton}>
      <Stack direction="row" gap={1} alignItems="center" width="100%">
        {hasIcon && <ModelIcon iconId={iconId as ModelIconId} size="small" />}
        {!hasIcon && <Icon name="question-mark" color="grey" />}
        <Text>{name}</Text>
        {hasInfoButton && (
          <Stack flexDirection="row" justifyContent="flex-end" alignItems="center" flexGrow={1}>
            <Icon name="info-circle" onClick={handleClick} size="small" color="grey" />
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default PrivateModelButton;
