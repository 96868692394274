import { FC } from 'react';
import Page, { PageProps } from '@/components/page';
import Tabs from '@/components/tabs';
import IntForm from './int-form';
import ProfileForm from './profile-form';
import BrandingForm from './branding-form';
import CustomMessageForm from './custom-message-form';
import IdentForm from './ident-form';

const TabLabels = ['Profile', 'Integration', 'Identity', 'Branding', 'Custom Messages'];

const OrgSettingsPage: FC<PageProps> = () => {
  return (
    <Page title="Organization Settings">
      <Tabs
        labels={TabLabels}
        panels={[
          <ProfileForm />,
          <IntForm />,
          <IdentForm />,
          <BrandingForm />,
          <CustomMessageForm />,
        ]}
      />
    </Page>
  );
};

export default OrgSettingsPage;
