import { FC, useCallback } from 'react';
import Drawer, { DrawerProps } from '..';
import DrawerHeader from '../header';
import Text from '@/components/text';
import { lighten } from '@mui/material';
import { Box, Stack, Theme } from '@mui/material';
import DrawerContent from '../content';
import { useQuery } from 'react-query';
import { createUseStyles } from 'react-jss';
import { DateFormat, getNiceDate } from '@/lib/helpers';
import Chip from '@/components/chip';
import classNames from 'classnames';
import AccordionButton from '@/components/accordion-button';
import { getLatestUserEvent } from '@/lib/services/user-event.service';
import { ContextDetails, ShortcutMessage } from '@/lib/models/user-event';
import ShortcutHistory from '@/components/drawers/conversation/shortcut-history.tsx';
import ContextHistory from './context-history';
import { capitalize } from 'lodash';
import { QueryKey } from '@/lib/query-client';
import { UserEventModel } from '@/lib/models/user-event/user-event.model';

interface Props extends DrawerProps {
  conversationId: string;
  requestId?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  content: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    borderRadius: theme.shape.borderRadius,
    marginBottom: 4,
    padding: 10,
    '&.user': {
      backgroundColor: lighten(theme.palette.primary.light, 0.8),
    },
    '&.violations': {
      backgroundColor: '#f044381f',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '&.focus': {
      borderLeft: `8px solid ${theme.palette.surface.primary.main}`,
    },
  },
  messageRow: {
    marginBottom: 24,
    position: 'relative',
  },
  violationHeader: {
    backgroundColor: theme.palette.grey[100],
    padding: '5px 10px',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    '&.focus': {
      borderLeft: `8px solid ${theme.palette.surface.primary.main}`,
    },
  },
}));

export const ConversationDrawer: FC<Props> = ({
  conversationId,
  requestId,
  open,
  onClose,
  className,
}) => {
  const styles = useStyles();
  const query = useQuery(
    [QueryKey.UserEventConversationHistory, conversationId],
    async () => getLatestUserEvent(conversationId),
    {
      enabled: !!conversationId,
    }
  );
  const { data = new UserEventModel() } = query;
  const userEvent = data || new UserEventModel();

  const { serviceName, userClaim, messages } = userEvent;
  const { name, email } = userClaim;
  const nameDisplay = name ? `${name} (${email})` : email;
  const nameMap = {
    context: 'context',
    user: nameDisplay || 'user',
    assistant: serviceName || 'assistant',
  };
  const conversation = messages.conversation || [];

  const requestMessageRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      node.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  let messageRefId = '';
  const conversationHasLength = conversation.length > 2;

  return (
    <Drawer open={open} onClose={onClose} className={className} query={query}>
      <DrawerHeader onClose={onClose}>Conversation History</DrawerHeader>
      <DrawerContent>
        {conversation.map(
          (
            {
              role,
              content,
              violations,
              origContent,
              timestamp,
              requestId: messageRequestId,
              shortcut = {},
              contextDetails = {},
            },
            index
          ) => {
            const isUser = role === 'user';
            const messageDate = getNiceDate(timestamp, DateFormat.FULL);
            const hasOriginal = !!origContent;

            const hasSensitiveData = !!violations?.sensitiveData;
            const hasAccess = !!violations?.access;
            const hasIntent = !!violations?.intent;
            const hasOutput = !!violations?.output;
            const hasInput = !!violations?.input;
            const hasViolations =
              isUser && (hasSensitiveData || hasAccess || hasIntent || hasOutput || hasInput);

            const focusMessage = conversationHasLength && requestId === messageRequestId;
            let assignMessageRef = false;

            if (focusMessage && !messageRefId) {
              messageRefId = messageRequestId;
              assignMessageRef = true;
            }

            const compClass = classNames(styles.content, {
              user: isUser,
              violations: hasViolations,
              focus: focusMessage,
            });

            const compViolationClass = classNames(styles.violationHeader, {
              focus: focusMessage,
            });

            let ContentElement = <Text size="small">{content}</Text>;

            if (shortcut.id)
              ContentElement = <ShortcutHistory shortcut={shortcut as ShortcutMessage} />;

            if (contextDetails.name) {
              ContentElement = (
                <ContextHistory
                  contextDetails={contextDetails as ContextDetails}
                  content={content}
                />
              );
            }

            return (
              <Stack
                className={styles.messageRow}
                key={index}
                ref={assignMessageRef ? requestMessageRef : null}
                width="100%"
              >
                <Box display="flex" justifyContent="space-between" mb={0.8}>
                  <Box maxWidth="60%">
                    <Text bold size="small" dotdot>
                      {nameMap[role] === 'context'
                        ? `${capitalize(contextDetails.origin)} Context`
                        : nameMap[role]}
                    </Text>
                  </Box>

                  <Text color="grey" size="small">
                    {messageDate.formatted}
                  </Text>
                </Box>

                {hasViolations && (
                  <Box className={compViolationClass}>
                    {hasSensitiveData && <Chip label="PII" color="error" size="small" />}
                    {hasAccess && <Chip label="ACCESS" color="error" size="small" />}
                    {hasIntent && <Chip label="INTENT" color="error" size="small" />}
                    {hasInput && <Chip label="INPUT" color="error" size="small" />}
                    {hasOutput && <Chip label="OUTPUT" color="error" size="small" />}
                  </Box>
                )}

                <Box className={compClass}>
                  {hasAccess ? (
                    <Text italic size="small">
                      message blocked
                    </Text>
                  ) : (
                    ContentElement
                  )}
                </Box>
                {hasOriginal && (
                  <AccordionButton
                    title={
                      <Text size="small" bold>
                        Original Message
                      </Text>
                    }
                  >
                    <Text size="small">{origContent}</Text>
                  </AccordionButton>
                )}
              </Stack>
            );
          }
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ConversationDrawer;
