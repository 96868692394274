import { FC, useRef } from 'react';
import Page, { PageProps } from '../../../components/page';
import { Box } from '@mui/material';
import DataTable, { DataTableInstance } from '../../../components/data-table';
import PageHeader, { PageBlurb } from '../../../components/page/header';
import useDrawer from '../../../hooks/use-drawer';
import Button from '../../../components/button';
import { DtColumn, DtFilter, DtSort } from '@/components/data-table/helpers';
import OpenDrawerButton from '@/components/drawers/open-drawer-button';
import { QueryKey } from '@/lib/query-client';
import DomSize from '@/components/dom-size';
import { getTelemetry } from '@/lib/services/telemetry.service';
import useFeature from '@/hooks/use-feature';
import Tooltip from '@/components/tooltip';
import PageFeatureToast from '@/components/page-feature-toast';
import { useQuery } from 'react-query';
import { getSiemConnectors } from '@/lib/services/connector.service';
import useQueryHelper from '@/hooks/use-query-helper';
import NoneChip from '@/components/chip/none-chip';
import StatusChip, { ChipStatus } from '@/components/chip/status-chip';
import { getTypedTelemetry } from '@/lib/models/telemetry';

const columns: DtColumn[] = [
  { name: 'displayName', label: 'Name', sortable: true },
  { name: 'type', label: 'Type', sortable: false },
  { name: 'connector', label: 'Connector', sortable: false },
  { name: 'status', label: 'Status', sortable: true },
  { name: 'enabled', label: 'Activation', sortable: true },
  { name: 'action', label: '', sortable: false },
];

const TelemetryPage: FC<PageProps> = () => {
  const { openDrawer, DrawerEl } = useDrawer('telemetry');

  const { canChangeTelemetry, getTooltip, loading: featureLoading } = useFeature();

  const connectorQuery = useQuery([QueryKey.ConnectorSiemsList], async () => getSiemConnectors());
  const { showLoader: connectorsLoading } = useQueryHelper(connectorQuery);
  const { data: connectors } = connectorQuery;

  const dtRef = useRef<DataTableInstance>(null);

  const handleRefresh = () => {
    dtRef.current?.refresh();
  };

  const handleView = (id?: string) => {
    openDrawer({ id, onChange: handleRefresh });
  };

  const loadData = async (page: number, pageSize: number, sort?: DtSort, filter?: DtFilter) => {
    const dataset = await getTelemetry(page, pageSize, sort, filter);

    return {
      ...dataset,
      rows: dataset.rows.map((telemetry) => {
        const { id, displayName, connectorId, status, enabled } = telemetry;
        const connector = connectors?.find(({ id }) => id === connectorId);

        const typedTelemetry = getTypedTelemetry(telemetry);

        return {
          id,
          displayName: <Box maxWidth="100%">{displayName}</Box>,
          connector: connector?.name || <NoneChip notAvailable />,
          type: typedTelemetry.typeLabel,
          status: <StatusChip value={status as ChipStatus} size="small" />,
          enabled: <StatusChip value={enabled ? 'enabled' : 'disabled'} size="small" />,
          action: (
            <Box width="100%" display="flex" justifyContent="flex-end">
              <OpenDrawerButton onClick={() => handleView(id)} />
            </Box>
          ),
        };
      }),
    };
  };

  if (featureLoading || connectorsLoading) {
    return null;
  }

  return (
    <Page title="Telemetry Destination">
      <PageHeader>
        <PageBlurb>
          Create new telemetry destinations, and view and change settings for existing destinations.
        </PageBlurb>
        <>
          <Tooltip title={getTooltip('add-telemetry')} disabled={canChangeTelemetry}>
            <Button
              label="Add Destination"
              icon="plus"
              size="small"
              onClick={() => handleView()}
              disabled={!canChangeTelemetry}
            />
          </Tooltip>
        </>
      </PageHeader>
      <PageFeatureToast featureId="change-telemetry" can={canChangeTelemetry} />
      <DomSize>
        <DataTable
          queryKey={QueryKey.TelemetryDataset}
          columns={columns}
          onLoad={loadData}
          ref={dtRef}
          search={true}
          searchPlaceholder="Search by name"
          sort={{ columnName: 'displayName', direction: 'asc' }}
        />
      </DomSize>
      {DrawerEl}
    </Page>
  );
};

export default TelemetryPage;
