import { FC } from 'react';
import MuiChip from '@mui/material/Chip';
import { AlertColor, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Icon, { IconType } from '../icon';

export type ChipColor = AlertColor | 'primary' | 'secondary' | 'default' | 'grey' | 'critical';

interface Props {
  icon?: IconType;
  label: string;
  color?: ChipColor; // @todo mui type for this?
  size?: 'x-small' | 'small' | 'medium';
  className?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  chip: {
    width: 'fit-content',
    '&.info': {
      backgroundColor: theme.palette.surface.info.main,
      '& .MuiChip-label': {
        color: '#0e7090',
      },
    },
    '&.error': {
      backgroundColor: theme.palette.surface.error.main,
      '& .MuiChip-label': {
        color: '#b42318',
      },
    },
    '&.warning': {
      backgroundColor: theme.palette.surface.warning.main,
      '& .MuiChip-label': {
        color: '#b44f18',
      },
    },
    '&.success': {
      backgroundColor: theme.palette.surface.success.main,
      '& .MuiChip-label': {
        color: '#0e9032',
      },
    },
    '&.primary': {
      backgroundColor: theme.palette.primary.light,
      '& .MuiChip-label': {
        color: '#ffffff',
      },
    },
    '&.secondary': {
      backgroundColor: theme.palette.secondary.light,
      '& .MuiChip-label': {
        color: '#ffffff',
      },
    },
    '&.grey': {
      backgroundColor: theme.palette.grey[300],
      '& .MuiChip-label': {
        color: theme.palette.grey[600],
      },
    },
    '&.critical': {
      backgroundColor: '#b42318',
      '& .MuiChip-label': {
        color: theme.palette.common.white,
      },
    },
    '&.small': {
      height: 25,
    },
    '&.x-small': {
      height: 22,
      fontSize: theme.typography.caption.fontSize,
    },
    '& .MuiChip-avatar': {
      fontSize: 'inherit !important',
      width: 'auto',
      height: 'auto',
    },
    '&.withIcon .MuiChip-label': {
      marginTop: -2,
    },
    '& .MuiChip-labelSmall': {
      lineHeight: '100%',
    },
  },
}));

const Chip: FC<Props> = ({ label, size = 'medium', color = 'default', className = '', icon }) => {
  const styles = useStyles();
  const compClass = classNames(styles.chip, className, color, size, { withIcon: !!icon });
  const muiSize = size === 'x-small' ? 'small' : size;
  const IconEl = icon ? <Icon size={muiSize} name={icon} /> : undefined;

  return (
    <MuiChip avatar={IconEl} className={compClass} label={label} size={muiSize} variant="filled" />
  );
};

export default Chip;
