import { FC, useCallback, useMemo, useState } from 'react';
import Page, { PageProps } from '@/components/page';
import PageHeader, { PageBlurb } from '@/components/page/header';
import { getAllConnectors, maskConnectorStatus } from '@/lib/services/connector.service';
import { Box } from '@mui/material';
import ConnectorIcon from '@/components/connector-icon';
import useDrawer from '@/hooks/use-drawer';
import Button from '@/components/button';
import Tooltip from '@/components/tooltip';
import {
  ConnectorFeature,
  ConnectorFeatureLabels,
  ConnectorType,
  getConnectorOptions,
  getTypedConnector,
} from '@/lib/models/connector';
import { QueryKey } from '@/lib/query-client';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import { GridCard, GridCardCategory } from '@/components/card-grid/helpers';
import { useQuery } from 'react-query';
import CardGrid from '@/components/card-grid';
import { createUseStyles } from 'react-jss';
import StatusChip from '@/components/chip/status-chip';
import { sortBy, uniqBy } from 'lodash';
import { SortButton } from '@/components/button-bar/helpers';
import useQueryHelper from '@/hooks/use-query-helper';

const useStyles = createUseStyles({
  connectorIcon: {
    justifyContent: 'flex-start',
  },
});

const SortButtons: SortButton[] = [
  {
    sortType: 'alpha',
    icon: 'alpha-sort',
    tooltip: 'Sort alphabetically by name',
  },
  {
    sortType: 'category',
    icon: 'sort',
    tooltip: 'Sort by category',
  },
];

const categoryEnabled = (category: string) =>
  ['privateModels', 'contextData', 'ingress'].includes(category);

const ConnectorsPage: FC<PageProps> = () => {
  const { openDrawer, DrawerEl } = useDrawer('connector');
  const styles = useStyles();
  const [availableSort, setAvailableSort] = useState<string>('category');
  const [enabledSort, setEnabledSort] = useState<string>('alpha');

  const { canChangeConnector, getTooltip, loading: featureLoading, ragStatus } = useFeature();
  const canChange = canChangeConnector();

  const connectorsQuery = useQuery([QueryKey.ConnectorsList], async () => getAllConnectors());
  const { data: connectors, refetch } = connectorsQuery;
  const { showLoader: connectorsLoading } = useQueryHelper(connectorsQuery);

  const handleRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleOpenDrawer = useCallback(
    (connectorId?: string) => {
      openDrawer({
        id: connectorId || null,
        onChange: handleRefresh,
      });
    },
    [openDrawer, handleRefresh]
  );

  const handleAdd = useCallback(
    (connectorType: ConnectorType, typeLabel: string) => {
      openDrawer({
        connectorType,
        name: `${typeLabel} Connector`,
        onChange: handleRefresh,
      });
    },
    [openDrawer, handleRefresh]
  );

  const handleSortAvailable = (sortType: string) => {
    setAvailableSort(sortType);
  };

  const handleSortEnabled = (sortType: string) => {
    setEnabledSort(sortType);
  };

  const [enabledCards, enabledCategories]: [GridCard[], GridCardCategory[]] = useMemo(() => {
    const categories: GridCardCategory[] = [];
    let cards: GridCard[] = [];
    const hasCategories = enabledSort === 'category';

    const maskedConnectors = maskConnectorStatus(connectors?.rows || [], ragStatus).map(
      getTypedConnector
    );

    maskedConnectors.forEach((connector) => {
      const { id, name, type, typeLabel, status, supportedFeatures } = connector;
      const showStatus = status !== 'active';

      const activeFeatureIds = Object.keys(supportedFeatures)
        .filter((featureKey) => {
          const featureId = featureKey as ConnectorFeature;
          return categoryEnabled(featureId) && supportedFeatures[featureId];
        })
        .map((featureKey) => featureKey as ConnectorFeature);

      activeFeatureIds.forEach((featureId) => {
        if (categories.find(({ id }) => id === featureId)) {
          return;
        }

        categories.push({
          title: ConnectorFeatureLabels[featureId],
          id: featureId,
        });
      });

      const card = {
        id: `${id}-${type}`,
        IconEl: (
          <ConnectorIcon connectorType={type} size="large" iconClass={styles.connectorIcon} />
        ),
        children: (
          <Box
            position="relative"
            flexGrow={1}
            width="100%"
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            {showStatus && <StatusChip value={status} size="small" />}
          </Box>
        ),
        title: name,
        detail: typeLabel,
        onClick: () => handleOpenDrawer(id),
      };

      if (hasCategories) {
        activeFeatureIds.forEach((featureId) => {
          cards.push({
            ...card,
            categoryId: featureId,
            id: `${card.id}-${featureId}`,
          });
        });
        return;
      }

      cards.push(card);
    });

    cards = sortBy(cards, ['categoryId', 'typeLabel']);

    return [cards, hasCategories ? categories : []];
  }, [connectors, handleOpenDrawer, ragStatus, styles.connectorIcon, enabledSort]);

  const [availableCards, availableCategories]: [GridCard[], GridCardCategory[]] = useMemo(() => {
    const categories: GridCardCategory[] = [];
    let cards: (GridCard & { connectorType: ConnectorType })[] = [];

    const connectorOpts = getConnectorOptions();

    Object.keys(ConnectorFeatureLabels).forEach((category) => {
      const featureId = category as ConnectorFeature;

      if (!categoryEnabled(category)) {
        return;
      }

      categories.push({
        title: ConnectorFeatureLabels[featureId],
        id: category,
      });

      connectorOpts
        .filter(({ meta }) => meta?.[featureId])
        .forEach(({ label, value, disabled }) => {
          const connectorType = value as ConnectorType;
          const title = label as string;

          cards.push({
            id: `${category}-${connectorType}`,
            IconEl: (
              <ConnectorIcon
                connectorType={connectorType}
                size="large"
                iconClass={styles.connectorIcon}
              />
            ),
            children: (
              <Box
                position="relative"
                flexGrow={1}
                width="100%"
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              ></Box>
            ),
            title,
            detail: disabled ? 'Coming Soon' : '',
            onClick: disabled ? undefined : () => handleAdd(connectorType, title),
            categoryId: category,
            connectorType,
          });
        });
    });

    cards = sortBy(cards, ['title']);

    if (availableSort !== 'category') {
      cards = uniqBy(cards, 'connectorType');
      return [cards, []];
    }

    return [cards, categories];
  }, [handleAdd, styles.connectorIcon, availableSort]);

  if (connectorsLoading || featureLoading) {
    return null;
  }

  return (
    <Page title="Connectors">
      <PageHeader>
        <PageBlurb>Manage connections to third-party services and networks.</PageBlurb>
        <>
          <Tooltip title={getTooltip('add-connector')} disabled={canChange}>
            <Button
              label="Add Connector"
              icon="plus"
              size="small"
              onClick={() => handleOpenDrawer()}
              disabled={!canChange}
            />
          </Tooltip>
        </>
      </PageHeader>
      <PageFeatureToast featureId="change-connector" can={canChange} />
      <CardGrid
        title="Enabled Connectors"
        cards={enabledCards}
        categories={enabledCategories}
        sortType={enabledSort}
        sortButtons={SortButtons}
        onSort={handleSortEnabled}
        showAdd={false}
        showEmpty
        onRefresh={handleRefresh}
      />

      <Box mt={4}>
        <CardGrid
          title="Available Connectors"
          cards={availableCards}
          categories={availableCategories}
          sortType={availableSort}
          sortButtons={SortButtons}
          onSort={handleSortAvailable}
          showAdd={false}
          showEmpty
        />
      </Box>

      {DrawerEl}
    </Page>
  );
};

export default ConnectorsPage;
