import { FC } from 'react';
import Chip from '.';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material';

interface Props {
  notAvailable?: boolean;
  redact?: boolean;
  length?: number;
}

const useStyles = createUseStyles<string, { redact: boolean }>((theme: Theme) => ({
  noneChip: {
    opacity: 0.5,
    border: `1px dashed ${theme.palette.grey[400]}`,
    background: 'none',
    '& .MuiChip-label': {
      paddingTop: ({ redact }) => (redact ? 7 : 0),
    },
  },
}));

const NoneChip: FC<Props> = ({ notAvailable, redact = false, length = 8 }) => {
  const styles = useStyles({ redact });

  let label = 'none';

  if (notAvailable) {
    label = 'n/a';
  }

  if (redact) {
    label = '*'.repeat(length);
  }

  return <Chip label={label} size="small" className={styles.noneChip} />;
};

export default NoneChip;
