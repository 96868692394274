import { JsonObject, UiOption } from '@/lib/helpers';
import { AwsS3TelemetryConfig, AwsS3TelemetryModel } from './aws-s3-telemetry.model';
import { TelemetryModel } from './telemetry.model';
import { cloneDeep } from 'lodash';

export type TelemetryActionTypes = {
  userEvents: boolean;
};

export type TelemetryType = 's3' | 'unknown';

export type TelemetryConfig = AwsS3TelemetryConfig;

export const TelemetryMap: Map<TelemetryType, TelemetryModel> = new Map();

TelemetryMap.set('s3', new AwsS3TelemetryModel());

export const getTypedTelemetry = (model: TelemetryModel) => {
  const typedModel = TelemetryMap.get(model.type);

  if (!typedModel) {
    return model;
  }

  const Constructor = typedModel.constructor as new (data?: JsonObject) => typeof typedModel;

  const config = {
    ...typedModel.config,
    ...model.config,
  };

  const data = cloneDeep({ ...model._props, config });

  return new Constructor(data)!;
};

export const getTelemetryOptions = (): UiOption[] => {
  return Array.from(TelemetryMap.entries()).map(([type, model]) => {
    const { typeLabel } = model;

    return {
      label: typeLabel,
      value: type as string,
    };
  });
};
