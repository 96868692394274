import { JsonObject } from '../../helpers';
import { TelemetryModel } from './telemetry.model';

export type AwsS3TelemetryConfig = {
  bucketName: string;
  bucketRegion: string;
};

export class AwsS3TelemetryModel extends TelemetryModel {
  config: AwsS3TelemetryConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof TelemetryModel, unknown>;

    this.type = 's3';
    this.typeLabel = 'AWS S3 Bucket';
    this.config = (data.config as AwsS3TelemetryConfig) || { bucketName: '', bucketRegion: '' };
  }
}
