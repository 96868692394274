import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FC, useState } from 'react';
import InputControl from '@/components/form/input-control';
import { ViewState } from '@/lib/helpers';
import { Box } from '@mui/material';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { AzureOpenAIConnectorModel } from '@/lib/models/connector/azure-openai-connector.model';
import { getTypedConnector } from '@/lib/models/connector';
import TextInput from '@/components/form/text-input';

interface Props {
  connector: AzureOpenAIConnectorModel;
  formHandle: FormHandle<ConnectorModel>;
  mode: ViewState;
}

const AzureOpenAIConnector: FC<Props> = ({ formHandle, mode, connector }) => {
  const inWriteMode = ['edit', 'add'].includes(mode);

  const { accountName: propAccountName } = getTypedConnector(
    connector
  ) as AzureOpenAIConnectorModel;
  const [accountName, setAccountName] = useState<string>(propAccountName);

  const { setFieldValue } = formHandle;
  const isNew = !connector.id;

  const handleChangeAccountName = (val: string) => {
    setAccountName(val);
    setFieldValue('config.endpoint', AzureOpenAIConnectorModel.getGetEndpointUrl(val));
  };

  return (
    <>
      {inWriteMode && (
        <Box>
          <InputControl
            name="config.apiKey"
            label="API Key"
            formHandle={formHandle}
            updateOnly={!isNew}
          />

          <TextInput
            name="accountName"
            label="Account Name"
            value={accountName}
            onChange={handleChangeAccountName}
          />

          <InputControl name="config.endpoint" label="Endpoint" formHandle={formHandle} readonly />
        </Box>
      )}
    </>
  );
};

export default AzureOpenAIConnector;
