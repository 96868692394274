import { JsonObject } from '@/lib/helpers';
import { AwsPrivateModelConfig, AwsPrivateModelModel } from './aws-private-model.model';
import { PrivateModelModel } from './private-model.model';
import { cloneDeep } from 'lodash';

export type PrivateModelType = 'aws' | 'openai' | 'azure-openai' | 'gemini';

export type PrivateModelBaseConfig = {
  model: string;
  modelId: string;
};

export type PrivateModelConfig = PrivateModelBaseConfig | AwsPrivateModelConfig;

export const PrivateModelMap: Map<PrivateModelType, PrivateModelModel> = new Map();

PrivateModelMap.set('aws', new AwsPrivateModelModel());

export const getTypedPrivateModel = (model: PrivateModelModel) => {
  const typedModel = PrivateModelMap.get(model.type);

  if (!typedModel) {
    return model;
  }

  const Constructor = typedModel.constructor as new (data?: JsonObject) => typeof typedModel;

  const providerModelProperties = {
    ...typedModel.providerModelProperties,
    ...model.providerModelProperties,
  };

  const data = cloneDeep({ ...model._props, providerModelProperties });

  return new Constructor(data)!;
};
