import { FC, useMemo } from 'react';
import Select from '../select';
import { FormProps } from '../helpers';
import { AWS_REGION_OPTS } from '@/lib/helpers';
import { Stack } from '@mui/material';
import Text from '@/components/text';
import Chip from '@/components/chip';

interface Props extends FormProps {
  value: string;
  onChange: (value: string) => void;
}

const AwsRegionSelect: FC<Props> = ({ value, onChange, ...rest }) => {
  const regionOpts = useMemo(() => {
    return AWS_REGION_OPTS.map(({ label, value, meta }) => ({
      label: meta?.header ? (
        label
      ) : (
        <Stack direction="row" alignItems="center" gap={1}>
          <Text>{label}</Text>
          <Chip size="small" label={value} />
        </Stack>
      ),
      value,
      meta,
    }));
  }, []);

  const props = {
    ...rest,
    value,
    onChange,
    options: regionOpts,
    label: 'AWS Region',
  };

  return <Select {...props} />;
};

export default AwsRegionSelect;
