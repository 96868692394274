import { object, string } from 'yup';
import { BaseModel } from '../base.model';
import { JsonObject } from '../../helpers';
import { TelemetryActionTypes, TelemetryConfig, TelemetryType } from '.';

export class TelemetryModel extends BaseModel {
  orgId: string;
  type: TelemetryType;
  typeLabel: string;
  status: string;
  statusMessage: string;
  connectorId: string;
  enabled: boolean;
  displayName: string;
  config: TelemetryConfig;
  telemetryTypes: TelemetryActionTypes;
  lastSyncedUserEventId: string;
  lastSyncedAt: string;
  updatedAt: string;

  schema = object({
    displayName: string().required('Name is required'),
    connectorId: string().required('Connector is required'),
    type: string().required('Type is required'),
  });

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof TelemetryModel, unknown>;

    this.orgId = (data.orgId as string) || '';
    this.type = (data.type as TelemetryType) || '';
    this.typeLabel = (data.typeLabel as string) || 'Unknown';
    this.status = (data.status as string) || '';
    this.statusMessage = (data.statusMessage as string) || '';
    this.connectorId = (data.connectorId as string) || '';
    this.enabled = !!data.enabled;
    this.displayName = (data.displayName as string) || '';
    this.config = (data.config as TelemetryConfig) || {};
    this.telemetryTypes = (data.telemetryTypes as TelemetryActionTypes) || { userEvents: true };
    this.lastSyncedUserEventId = (data.lastSyncedUserEventId as string) || '';
    this.lastSyncedAt = (data.lastSyncedAt as string) || '';
    this.updatedAt = (data.updatedAt as string) || '';
  }
}

export class CreateTelemetryModel extends BaseModel {
  type: TelemetryType;
  connectorId: string;
  enabled: boolean;
  displayName: string;
  config: TelemetryConfig;
  telemetryTypes: TelemetryActionTypes;

  constructor(telemetry: TelemetryModel) {
    super();

    this.type = telemetry.type;
    this.connectorId = telemetry.connectorId;
    this.enabled = telemetry.enabled;
    this.displayName = telemetry.displayName;
    this.config = telemetry.config;
    this.telemetryTypes = telemetry.telemetryTypes;
  }
}
