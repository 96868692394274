import { flatten, uniq } from 'lodash';
import ModelIcon, { ModelIconId } from '@/components/model-icon';
import Page, { PageProps } from '@/components/page';
import PageHeader, { PageBlurb } from '@/components/page/header';
import useDrawer from '@/hooks/use-drawer';
import useQueryHelper from '@/hooks/use-query-helper';
import { getConnectorsByIds, getPrivateModelConnectors } from '@/lib/services/connector.service';
import {
  getAllPrivateModels,
  getAvailablePrivateModels,
  getModelUsage,
} from '@/lib/services/private-model.service';
import { Box } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { AvailablePrivateModel } from '@/lib/models/available-private-model.model';
import { QueryKey } from '@/lib/query-client';
import { ChipStatus } from '@/components/chip/status-chip';
import InlineToast from '@/components/toasts/inline';
import CardGrid from '@/components/card-grid';
import PrivateModelCardFooter from './private-model-card-footer';
import { GridCard, GridCardCategory } from '@/components/card-grid/helpers';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import Tooltip from '@/components/tooltip';
import Button from '@/components/button';

type AvailableModelMap = Map<string, AvailablePrivateModel[]>;

const PrivateModelsPage: FC<PageProps> = () => {
  const connectorQuery = useQuery([QueryKey.ConnectorModelsList], async () =>
    getPrivateModelConnectors()
  );
  const { data: allModelConnectors } = connectorQuery;
  const { showLoader: connectorLoading } = useQueryHelper(connectorQuery);
  const hasConnector = !!allModelConnectors?.length;

  const { canChangePrivateModels, getTooltip } = useFeature();

  const query = useQuery([QueryKey.PrivateModelsDataset], async () => getAllPrivateModels());
  const { data: privateModelData, refetch } = query;
  const privateModels = privateModelData || [];

  const availableQuery = useQuery([QueryKey.PrivateModelsAvailable], async () => {
    const data = await getAvailablePrivateModels();
    const modelCategories: AvailableModelMap = new Map();

    data.rows.forEach((model) => {
      const providerName = model.modelProviderName;
      const modelCategory = modelCategories.get(providerName) || [];

      /*
       * A model can be listed as available once per connector, but we only want to show it once per category
       * when enumerating available models, especially since we don't currently show which connector a model
       * is available for in this category view.
       */
      const hasMatch = modelCategory.some((matchModel) => matchModel.isMatch(model));

      if (!hasMatch) {
        modelCategory.push(model);
        modelCategories.set(providerName, modelCategory);
      }
    });

    return modelCategories;
  });

  const { data: availableModelCategories } = availableQuery;
  const { showLoader: availableLoading } = useQueryHelper(availableQuery);

  const usageQuery = useQuery(
    [QueryKey.PrivateModelUsage, privateModels],
    async () => {
      const modelIds = uniq(privateModels.map(({ id }) => id));
      const connectorIds = uniq(
        privateModels.map(({ connectorId }) => connectorId).filter((v) => v)
      );
      return Promise.all([getModelUsage(modelIds), getConnectorsByIds(connectorIds)]);
    },
    { enabled: !!privateModels.length }
  );

  const { showLoader: usageLoading } = useQueryHelper(usageQuery);
  const { data: usageData } = usageQuery;
  const [usage, connectors] = usageData || [];

  const handleRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  const { openDrawer, DrawerEl } = useDrawer('private-model');

  const handleOpenDrawer = useCallback(
    (modelId?: string) => {
      openDrawer({ id: modelId || null, onChange: handleRefresh });
    },
    [openDrawer, handleRefresh]
  );

  const handleAdd = () => {
    openDrawer({ onChange: handleRefresh });
  };

  const enabledModelCards: GridCard[] = useMemo(() => {
    return (privateModelData || []).map(({ id, name, iconId, modelId, connectorId }) => {
      const modelUsage = usage?.get(id) || { inUse: false };
      const hasUsage = modelUsage.inUse;
      const hasConnector = !!connectors?.find(({ id }) => id === connectorId);

      let status: ChipStatus | undefined = undefined;
      if (!usageLoading && !hasConnector) {
        status = 'error';
      } else if (hasUsage) {
        status = 'active';
      }

      return {
        id: `${id}-${status}`,
        IconEl: <ModelIcon iconId={iconId as ModelIconId} />,
        children: <PrivateModelCardFooter status={status} />,
        title: name,
        detail: modelId,
        onClick: () => handleOpenDrawer(id),
      };
    });
  }, [privateModelData, connectors, usage, usageLoading, handleOpenDrawer]);

  const [amCategoryCards, amCategories]: [GridCard[], GridCardCategory[]] = useMemo(() => {
    const categories: GridCardCategory[] = [];

    const cards = flatten(
      Array.from(availableModelCategories?.entries() || []).map(([category, models]) => {
        categories.push({ title: category, id: category });
        return models.map(({ modelId, name, iconId, inputModalities, outputModalities }) => {
          return {
            id: modelId,
            IconEl: <ModelIcon iconId={iconId as ModelIconId} />,
            children: (
              <PrivateModelCardFooter
                inputModalities={inputModalities}
                outputModalities={outputModalities}
              />
            ),
            title: name,
            detail: modelId,
            categoryId: category,
          };
        });
      })
    );

    return [cards, categories];
  }, [availableModelCategories]);

  if (connectorLoading || availableLoading) {
    return null;
  }

  return (
    <Page title="Private Models" query={query}>
      {hasConnector && (
        <>
          <PageHeader>
            <PageBlurb>View and change settings for private models.</PageBlurb>

            <Tooltip title={getTooltip('add-private-model')} disabled={canChangePrivateModels}>
              <Button
                label="Add Model"
                icon="plus"
                size="small"
                onClick={() => handleOpenDrawer()}
                disabled={!canChangePrivateModels}
              />
            </Tooltip>
          </PageHeader>
          <PageFeatureToast featureId="change-private-model" can={canChangePrivateModels} />
          <CardGrid
            title="Enabled Models"
            cards={enabledModelCards}
            canAdd={canChangePrivateModels}
            onAdd={handleAdd}
            addTitle="Add Private Model"
            addDisabledTooltip={getTooltip('add-private-model')}
            onRefresh={handleRefresh}
          />

          <Box mt={4}>
            <CardGrid
              title="Available Models"
              cards={amCategoryCards}
              categories={amCategories}
              showAdd={false}
              showEmpty
            />
          </Box>
        </>
      )}

      {!hasConnector && (
        <Box>
          <InlineToast level="warning" message="Create a connector to enable private models" />
        </Box>
      )}

      {DrawerEl}
    </Page>
  );
};

export default PrivateModelsPage;
