import Chip from '@/components/chip';
import NoneChip from '@/components/chip/none-chip';
import StatusChip from '@/components/chip/status-chip';
import ContextProvidersCard from '@/components/context-providers-card';
import ReadonlyView from '@/components/form/readonly-view';
import { ReadonlyField } from '@/components/form/readonly-view/helpers';
import TextInput from '@/components/form/text-input';
import PrivateModelsCard from '@/components/private-models-card';
import InlineToast from '@/components/toasts/inline';
import { JsonObject } from '@/lib/helpers';
import {
  ConnectorFeature,
  ConnectorFeatureLabels,
  getTypedConnector,
} from '@/lib/models/connector';
import { AwsConfig } from '@/lib/models/connector/aws-connector.model';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { NetskopeConfig } from '@/lib/models/connector/netskope-connector.model';
import { SharepointConfig } from '@/lib/models/connector/sharepoint-connector.model';
import { WebcrawlerConfig } from '@/lib/models/connector/webcrawler-connector.model';
import { ZscalerConfig } from '@/lib/models/connector/zscaler-connector.model';
import { PrivateModelModel } from '@/lib/models/private-model/private-model.model';
import { ContextProviderModel } from '@/lib/models/context-provider/context-provider.model';
import { Stack } from '@mui/material';
import { FC, useMemo } from 'react';
import { AzureOpenAIConnectorModel } from '@/lib/models/connector/azure-openai-connector.model';

interface Props {
  privateModels: PrivateModelModel[];
  contextProviders: ContextProviderModel[];
  connector: ConnectorModel;
  canViewArn: boolean;
}

const ConnectorReadonlyView: FC<Props> = ({
  connector,
  privateModels,
  contextProviders,
  canViewArn,
}) => {
  const [data, fields] = useMemo(() => {
    let fields: ReadonlyField[] = [
      { label: 'Name', prop: 'name' },
      { label: 'Type', prop: 'type' },
      { label: 'Status', prop: 'status' },
      { label: 'Status Message', prop: 'statusMessage' },
      { label: 'Supported Features', prop: 'supportedFeatures' },
      { label: 'Configuration', prop: 'config-section', propType: 'section' },
    ];

    const typedConnector = getTypedConnector(connector);
    const { name, type, typeLabel, status, error, supportedFeatures, config } = typedConnector;

    const activeFeatures = Object.keys(supportedFeatures).filter(
      (featureId) => supportedFeatures[featureId as keyof typeof supportedFeatures]
    );

    let data: JsonObject = {
      name,
      type: typeLabel,
      status: <StatusChip value={status} size="small" />,
      statusMessage: error ? <InlineToast hideIcon message={error} level="error" /> : <NoneChip />,
      supportedFeatures: (
        <Stack direction="row" gap={1}>
          {activeFeatures.map((featureId) => {
            const label = ConnectorFeatureLabels[featureId as ConnectorFeature] || featureId;
            return <Chip key={featureId} label={label} />;
          })}
        </Stack>
      ),
      privateModels: <PrivateModelsCard models={privateModels} />,
      contextProviders: <ContextProvidersCard contexts={contextProviders} />,
    };

    switch (type) {
      case 'aws':
        {
          const { awsAccountId, crossAccountRoleARN, awsRegion } = config as AwsConfig;
          const hasArn = crossAccountRoleARN;

          fields.push({ label: 'AWS Account ID', prop: 'awsAccountId' });
          data.awsAccountId = awsAccountId || <NoneChip />;

          fields.push({ label: 'AWS Region', prop: 'awsRegion' });
          data.awsRegion = awsRegion || <NoneChip />;

          if (canViewArn) {
            fields.push({ label: 'Cross Account Role ARN', prop: 'crossAccountRoleARN' });

            data.crossAccountRoleARN = hasArn ? (
              <TextInput
                name="cross-account-role-arn"
                disabled
                copyClip
                value={crossAccountRoleARN}
                fullWidth
                size="small"
              />
            ) : (
              <NoneChip />
            );
          }
        }
        break;
      case 'sharepoint-online':
        {
          const { tenantId, clientId, sharepointSite } = config as SharepointConfig;

          fields = [
            ...fields,
            { label: 'Tenant Id', prop: 'tenantId' },
            { label: 'Client Id', prop: 'clientId' },
            { label: 'SharePoint Base URL', prop: 'sharepointSite' },
          ];

          data = {
            ...data,
            tenantId,
            clientId,
            sharepointSite,
          };
        }
        break;
      case 'webcrawler':
        {
          const { url } = config as WebcrawlerConfig;

          fields.push({ label: 'URL', prop: 'url' });
          data.url = url;
        }
        break;
      default:
        fields.push({ label: '', prop: 'noConfig' });
        data.noConfig = <NoneChip />;

        break;
      case 'zscaler':
        {
          const { xAuthenticatedUserEnabled } = config as ZscalerConfig;

          fields.push({
            label: 'X-Authenticated-User Header',
            prop: 'xAuthenticatedUserEnabled',
          });
          data.xAuthenticatedUserEnabled = (
            <StatusChip value={xAuthenticatedUserEnabled ? 'enabled' : 'disabled'} />
          );
        }
        break;
      case 'netskope':
        {
          const { xAuthenticatedUserEnabled, tenantInfo } = config as NetskopeConfig;

          fields = [
            ...fields,
            {
              label: 'X-Authenticated-User Header',
              prop: 'xAuthenticatedUserEnabled',
            },
            {
              label: 'Tenant Info',
              prop: 'tenantInfo',
            },
          ];

          data.tenantInfo = tenantInfo || <NoneChip />;

          data.xAuthenticatedUserEnabled = (
            <StatusChip value={xAuthenticatedUserEnabled ? 'enabled' : 'disabled'} />
          );
        }
        break;

      case 'gemini':
      case 'openai':
      case 'brave-search':
        {
          fields = [
            ...fields,
            {
              label: 'API Key',
              prop: 'apiKey',
            },
          ];

          data.apiKey = <NoneChip redact length={32} />;
        }
        break;

      case 'azure-openai': {
        fields = [
          ...fields,
          {
            label: 'API Key',
            prop: 'apiKey',
          },
          {
            label: 'Account name',
            prop: 'accountName',
          },
        ];

        data.apiKey = <NoneChip redact length={32} />;
        data.accountName = (typedConnector as AzureOpenAIConnectorModel).accountName;
      }
    }

    // add usage fields
    const { privateModels: pmFeature, contextData: cdFeature } = supportedFeatures;
    if (pmFeature || cdFeature) {
      fields.push({ label: 'Usage', prop: 'usage-section', propType: 'section' });
      if (pmFeature) {
        fields.push({ label: '', prop: 'privateModels' });
      }

      if (cdFeature) {
        fields.push({ label: '', prop: 'contextProviders' });
      }
    }

    return [data, fields];
  }, [connector, privateModels, contextProviders, canViewArn]);

  return <ReadonlyView fields={fields} data={data} />;
};

export default ConnectorReadonlyView;
