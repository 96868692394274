import { JsonObject } from '../../helpers';
import { ConnectorFeatureSet } from './';
import AzureIcon from '@/assets/connector-icons/azure.svg';
import { ConnectorModel } from './connector.model';

export type AzureOpenAIConfig = {
  apiKey: string;
  endpoint: string; // ^https://[a-zA-Z0-9-]+\.openai\.azure\.com(/?)$
};

export class AzureOpenAIConnectorModel extends ConnectorModel {
  config: AzureOpenAIConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ConnectorModel, unknown>;

    this.type = 'azure-openai';
    this.typeLabel = 'Azure OpenAI';
    this.iconSVG = AzureIcon;

    this.supportedFeatures = (data.supportedFeatures as ConnectorFeatureSet) || {
      siem: false,
      privateModels: true,
      contextData: false,
      ingress: false,
    };

    this.config = (data.config as AzureOpenAIConfig) || {
      apiKey: '',
      endpoint: '',
    };

    this.configSecrets = ['apiKey'];
  }

  get accountName(): string {
    if (!this.config.endpoint) {
      return '';
    }

    try {
      const endpointUrl = new URL(this.config.endpoint);

      return endpointUrl.hostname?.split('.').shift() || '';
    } catch {
      return '';
    }
  }

  static getGetEndpointUrl(accountName: string): string {
    if (!accountName) {
      return '';
    }

    return `https://${accountName}.openai.azure.com`;
  }
}
