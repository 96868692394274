import { JsonObject } from '../../helpers';
import { ContextProviderModel } from './context-provider.model';

export type AwsKbConfig = {
  knowledgeBaseId: string;
  knowledgeBaseName: string;
};

export class AwsKbContextProviderModel extends ContextProviderModel {
  config: AwsKbConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ContextProviderModel, unknown>;

    this.type = 'customer-bedrock-knowledge-base';
    this.typeLabel = 'Bedrock Knowledge Base';
    this.source = 'knowledge-base';

    this.config = (data.config as AwsKbConfig) || {
      knowledgeBaseId: '',
      knowledgeBaseName: '',
    };

    this.scanable = false;
  }
}
