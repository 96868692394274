import { FC, useMemo } from 'react';
import FormButtons from '@/components/form/form-buttons';
import FormSection from '@/components/form/form-section';
import InputControl from '@/components/form/input-control';
import useFormHandle from '@/hooks/use-form-handle.hook';
import useQueryHelper from '@/hooks/use-query-helper';
import useToast from '@/hooks/use-toast.hook';
import { PolicyModel, PublicServiceBlockOptions } from '@/lib/models/policy.model';
import { QueryKey } from '@/lib/query-client';
import { getOrgPolicy, patchPolicy } from '@/lib/services/policy.service';
import { Box, Grid, Stack } from '@mui/material';
import { useQuery } from 'react-query';
import { Form } from '@/components/form';
import InlineToast from '@/components/toasts/inline';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import FormLabel from '@/components/form-label';
import { createUseStyles } from 'react-jss';
import { getAllPrivateModels } from '@/lib/services/private-model.service';
import ModelOption from '@/components/model-option';

const fields = ['publicServices', 'portal'];
const formModel = new PolicyModel();

const useStyles = createUseStyles({
  portalSelect: {
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
  },
});
const ModelAccessForm: FC = () => {
  const { errorToast, toast } = useToast();
  const { canChangePolicy } = useFeature();
  const styles = useStyles();

  const privateModelsQuery = useQuery([QueryKey.PrivateModelsList], async () =>
    getAllPrivateModels()
  );

  const { data: privateModels } = privateModelsQuery;

  const query = useQuery([QueryKey.OrgPolicyView], async () => getOrgPolicy());
  const { refetch } = query;

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updated = await patchPolicy(values.id, values.propSlice(fields));
      setSubmitting(false);
      resetForm({ values });

      if (updated) {
        toast('The policy was updated');
        refetch();
        return;
      }

      errorToast('The policy was not updated');
    },
  });

  const { values } = formHandle;

  const availablePrivateModelOptions = useMemo(() => {
    return (privateModels || []).map(({ name, id, iconId }) => {
      const isDefault = id === values.portal.defaultModelId;

      return {
        label: <ModelOption name={name} iconId={iconId} />,
        value: id,
        disabled: isDefault,
        tooltip: isDefault ? 'The default model must be enabled' : '',
      };
    });
  }, [privateModels, values.portal.defaultModelId]);

  const defaultPrivateModelOptions = useMemo(() => {
    return (privateModels || []).map(({ name, id, iconId }) => ({
      label: <ModelOption name={name} iconId={iconId} />,
      value: id,
      disabled: !values.portal.availablePrivateModelIds.includes(id),
    }));
  }, [privateModels, values.portal.availablePrivateModelIds]);

  const { showLoader } = useQueryHelper(query);
  const { showLoader: privateModelsLoading } = useQueryHelper(privateModelsQuery);

  if (showLoader || privateModelsLoading) {
    return;
  }

  const { allowPublicServicesByDefault, blockAction } = values.publicServices;

  const hasPrivateModel = !!availablePrivateModelOptions.length;
  const hasAllowPublicServices = !!allowPublicServicesByDefault;
  const hasRedirectUrl = blockAction === 'redirect-url';
  const hasPortalRedirect = blockAction === 'redirect-portal';
  const showPortlRedirectWarning = hasPortalRedirect && !values.portal.enabled;

  return (
    <Form formHandle={formHandle} query={query}>
      <PageFeatureToast featureId="change-policy" can={canChangePolicy} />

      <FormSection title="Allow Access to Portal">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Enable or disable portal access for your entire organization.
          </Grid>
          <Grid item xs={5}>
            <InputControl
              label="Portal Enabled"
              name="portal.enabled"
              type="switch"
              formHandle={formHandle}
              readonly={!canChangePolicy}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection title="Portal Private Model Settings">
        <Grid container columnSpacing={8} rowSpacing={4}>
          <Grid item xs={12}>
            Choose which private models are available, and the default, when a user is redirected to
            the Portal.
          </Grid>

          <Grid item xs={6}>
            <Box>
              <FormLabel>Enabled Private Models</FormLabel>
              <InputControl
                name="portal.availablePrivateModelIds"
                label=""
                type="switch-list"
                options={availablePrivateModelOptions}
                formHandle={formHandle}
                disabled={!hasPrivateModel}
                readonly={!canChangePolicy}
                emptyMessage="No private models available"
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <FormLabel>Default Private Model</FormLabel>
            <Box className={styles.portalSelect}>
              <InputControl
                label=""
                name="portal.defaultModelId"
                type="select"
                disabled={!hasPrivateModel}
                options={defaultPrivateModelOptions}
                formHandle={formHandle}
                readonly={!canChangePolicy}
              />
            </Box>

            {!privateModelsLoading && !hasPrivateModel && (
              <Box mt={2.5}>
                <InlineToast
                  level="warning"
                  message="Enable a Private Model to enable this feature"
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </FormSection>

      <FormSection title="Default Public Service Action">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            For any public service not listed below as "Allowed", determine how traffic to that
            service should be handled.
          </Grid>
          <Grid item xs={5}>
            <InputControl
              label="Default Public Service Action"
              name="publicServices.allowPublicServicesByDefault"
              type="policy-public-action-select"
              formHandle={formHandle}
              readonly={!canChangePolicy}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection title="Public Service Block Action">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            When access to a public service is blocked, choose whether users are shown a no-access
            page, or redirected to either the Portal or a custom URL.
          </Grid>
          <Grid item xs={5}>
            <InputControl
              label="Block Action"
              name="publicServices.blockAction"
              type="select"
              options={PublicServiceBlockOptions}
              formHandle={formHandle}
              readonly={!canChangePolicy}
            />
          </Grid>
          <Grid item xs={6} justifyContent="center">
            {hasRedirectUrl && (
              <InputControl
                label="Redirect URL"
                name="publicServices.redirectUrl"
                formHandle={formHandle}
                readonly={!canChangePolicy}
              />
            )}
            {showPortlRedirectWarning && (
              <Stack height="100%" justifyContent="center">
                <Box mt={1}>
                  <InlineToast
                    level="warning"
                    message={`The Portal is not enabled. Please enable it by changing the "Allow Access to Portal" setting above.`}
                  />
                </Box>
              </Stack>
            )}
          </Grid>
        </Grid>
      </FormSection>

      <FormSection title="Allowed Public Services">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Use the table below to allow or deny public services for this organization. All users
            will have this policy applied, before applying group-level policies, when determining
            access to public services.
          </Grid>
          <Grid item xs={12}>
            {hasAllowPublicServices && (
              <Box mt={1} mb={3}>
                <InlineToast
                  level="warning"
                  message={`WARNING: This setting has no effect, since the Default Public Service Action is set to "Allow"`}
                />
              </Box>
            )}
            <InputControl
              name="publicServices.enabled"
              label="Public Services"
              formHandle={formHandle}
              readonly={!canChangePolicy}
              type="public-service-acl"
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormButtons formHandle={formHandle} readonly={!canChangePolicy} />
    </Form>
  );
};

export default ModelAccessForm;
