import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Form } from '@/components/form';
import FormButtons from '@/components/form/form-buttons';
import FormSection from '@/components/form/form-section';
import InputControl from '@/components/form/input-control';
import useFormHandle from '@/hooks/use-form-handle.hook';
import useQueryHelper from '@/hooks/use-query-helper';
import useToast from '@/hooks/use-toast.hook';
import { PolicyModel } from '@/lib/models/policy.model';
import { QueryKey } from '@/lib/query-client';
import { getOrgPolicy, getPolicyById, patchPolicy } from '@/lib/services/policy.service';
import { Box, Grid, Stack } from '@mui/material';
import useFeature from '@/hooks/use-feature';
import PageFeatureToast from '@/components/page-feature-toast';
import InlineToast from '@/components/toasts/inline';
import FormLabel from '@/components/form-label';
import { createUseStyles } from 'react-jss';
import { getAllPrivateModels } from '@/lib/services/private-model.service';
import Text from '@/components/text';
import { cloneDeep } from 'lodash';
import ModelOption from '@/components/model-option';
import Switch from '@/components/form/switch';

const fields = ['publicServices', 'portal'];
const formModel = new PolicyModel();

interface Props {
  policyId: string;
}

const useStyles = createUseStyles({
  portalSelect: {
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
  },
});

const ModelAccessForm: FC<Props> = ({ policyId }) => {
  const { errorToast, toast } = useToast();
  const { canChangePolicy } = useFeature();
  const styles = useStyles();

  const query = useQuery([QueryKey.PolicyCompyView, policyId], async () => {
    const [policy, orgPolicy] = await Promise.all([getPolicyById(policyId), getOrgPolicy()]);

    if (policy && orgPolicy) {
      policy.applyOrgPolicy(orgPolicy);
    }

    return [policy, orgPolicy];
  });
  const { data, refetch } = query;
  const [, orgPolicy] = (data || [null, null]) as [PolicyModel | null, PolicyModel | null];

  const privateModelsQuery = useQuery([QueryKey.PrivateModelsList], async () =>
    getAllPrivateModels()
  );
  const { data: privateModels } = privateModelsQuery;

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schemaSlice(fields),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const updateValues = cloneDeep(values);

      if (orgPolicy) {
        updateValues.stripOrgPolicy(orgPolicy);
      }

      const updated = await patchPolicy(policyId, updateValues.propSlice(fields));

      setSubmitting(false);
      resetForm({ values });

      if (updated) {
        refetch();
        toast('The policy was updated');
        return;
      }

      errorToast('The policy was not updated');
    },
  });

  const { values } = formHandle;

  const availablePrivateModelOptions = useMemo(() => {
    const orgEnabledModels = orgPolicy?.portal?.availablePrivateModelIds || [];

    return (privateModels || []).map(({ name, id, iconId }) => {
      const orgEnabled = orgEnabledModels.includes(id);
      const isDefault = id === values.portal.defaultModelId;

      let tooltip = '';
      if (orgEnabled) {
        tooltip = 'This model is enabled by default policy and cannot be disabled by group policy';
      } else if (isDefault) {
        tooltip = 'The default model must be enabled';
      }

      return {
        label: <ModelOption name={name} iconId={iconId} />,
        value: id,
        disabled: orgEnabled || isDefault,
        tooltip,
      };
    });
  }, [privateModels, orgPolicy, values.portal.defaultModelId]);

  const defaultPrivateModelOptions = useMemo(() => {
    const orgDefaultModel = orgPolicy?.portal?.defaultModelId;

    return (privateModels || []).map(({ name, id, iconId }) => {
      const isOrgDefault = orgDefaultModel === id;

      const compName = isOrgDefault ? (
        <>
          {name}{' '}
          <Text inline color="grey" size="small">
            (Default Policy Model)
          </Text>
        </>
      ) : (
        name
      );

      return {
        label: <ModelOption name={compName} iconId={iconId} />,
        value: id,
        disabled: !values.portal.availablePrivateModelIds.includes(id),
      };
    });
  }, [privateModels, orgPolicy, values.portal.availablePrivateModelIds]);

  const { showLoader } = useQueryHelper(query);
  const { showLoader: privateModelsLoading } = useQueryHelper(privateModelsQuery);

  if (showLoader || privateModelsLoading) {
    return;
  }

  const hasAllowPublicServices = !!values.publicServices.allowPublicServicesByDefault;
  const hasPrivateModel = !!availablePrivateModelOptions.length;
  const canChangePortalEnabled = !orgPolicy?.portal?.enabled;

  return (
    <Form formHandle={formHandle} query={query}>
      <PageFeatureToast featureId="change-policy" can={canChangePolicy} />

      <FormSection title="Allow Access to Portal">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Enable or disable portal access for groups using this policy.
          </Grid>
          <Grid item xs={12}>
            {canChangePortalEnabled && (
              <InputControl
                label="Portal Enabled"
                name="portal.enabled"
                type="switch"
                formHandle={formHandle}
                readonly={!canChangePolicy}
              />
            )}

            {!canChangePortalEnabled && (
              <Stack direction="row" gap={2} alignItems="center">
                <Box>
                  <Switch
                    label="Portal Enabled"
                    name="canChangePortalEnabled"
                    checked
                    disabled
                    onChange={() => null}
                  />
                </Box>
                <InlineToast
                  level="warning"
                  message="The Portal is enabled by the Default Policy, and therefore cannot be disabled by group policy"
                />
              </Stack>
            )}
          </Grid>
        </Grid>
      </FormSection>

      <FormSection title="Portal Private Model Settings">
        <Grid container columnSpacing={8} rowSpacing={4}>
          <Grid item xs={12}>
            Choose which private models are available, and the default, when a user is redirected to
            the Portal.
          </Grid>

          <Grid item xs={6}>
            <Box>
              <FormLabel>Enabled Private Models</FormLabel>
              <InputControl
                name="portal.availablePrivateModelIds"
                label=""
                type="switch-list"
                options={availablePrivateModelOptions}
                formHandle={formHandle}
                disabled={!hasPrivateModel}
                readonly={!canChangePolicy}
                emptyMessage="No private models available"
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <FormLabel>Default Private Model</FormLabel>
            <Box className={styles.portalSelect}>
              <InputControl
                label=""
                name="portal.defaultModelId"
                type="select"
                disabled={!hasPrivateModel}
                options={defaultPrivateModelOptions}
                formHandle={formHandle}
                readonly={!canChangePolicy}
              />
            </Box>

            {!privateModelsLoading && !hasPrivateModel && (
              <Box mt={2.5}>
                <InlineToast
                  level="warning"
                  message="Enable a Private Model to enable this feature"
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </FormSection>

      <FormSection title="Default Public Service Action">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            For any public service not listed below as "Allowed", determine how traffic to that
            service should be handled.
          </Grid>
          <Grid item xs={5}>
            <InputControl
              label="Default Public Service Action"
              name="publicServices.allowPublicServicesByDefault"
              type="policy-public-action-select"
              formHandle={formHandle}
              readonly={!canChangePolicy}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection title="Allowed Public Services">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Use the table below to allow or deny public services for this group. Users belonging to
            this group will only have access to public services listed on the right under "Allowed
            Services".
          </Grid>
          <Grid item xs={12}>
            {hasAllowPublicServices && (
              <Box mt={1} mb={3}>
                <InlineToast
                  level="warning"
                  message={`WARNING: This setting has no effect, since the Default Public Service Action is set to "Allow"`}
                />
              </Box>
            )}

            <InputControl
              name="publicServices.enabled"
              label="Public Services"
              formHandle={formHandle}
              readonly={!canChangePolicy}
              type="public-service-acl"
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormButtons formHandle={formHandle} readonly={!canChangePolicy} />
    </Form>
  );
};

export default ModelAccessForm;
