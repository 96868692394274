import AI21Icon from '@/assets/model-icons/AI21.svg';
import AnthropicClaudeIcon from '@/assets/model-icons/anthropic-claude.svg';
import AWSIcon from '@/assets/model-icons/aws.svg';
import CohereCommandIcon from '@/assets/model-icons/cohere-command.svg';
import MetaLlamaIcon from '@/assets/model-icons/meta-llama2.svg';
import MistralIcon from '@/assets/model-icons/mistral.svg';
import StableDiffusionIcon from '@/assets/model-icons/stability-stable-diffusion.svg';
import OpenAIIcon from '@/assets/model-icons/openai.svg';
import GeminiIcon from '@/assets/connector-icons/google-gemini.png';
import { FC } from 'react';
import Icon from '@/components/icon';

export type ModelIconId =
  | 'anthropic'
  | 'claude'
  | 'ai21'
  | 'j2'
  | 'jamba'
  | 'titan'
  | 'command'
  | 'cohere'
  | 'meta'
  | 'llama2'
  | 'llama3'
  | 'stable'
  | 'mistral'
  | 'mixtral'
  | 'gemini'
  | 'gpt'
  | 'o1'
  | 'openai'
  | 'amazon'
  | 'nova';

interface Props {
  iconId: ModelIconId;
  size?: 'small' | 'medium';
}

const ModelIconMap: Map<ModelIconId, string> = new Map([
  ['claude', AnthropicClaudeIcon],
  ['anthropic', AnthropicClaudeIcon],
  ['ai21', AI21Icon],
  ['j2', AI21Icon],
  ['jamba', AI21Icon],
  ['titan', AWSIcon],
  ['amazon', AWSIcon],
  ['nova', AWSIcon],
  ['command', CohereCommandIcon],
  ['cohere', CohereCommandIcon],
  ['meta', MetaLlamaIcon],
  ['llama2', MetaLlamaIcon],
  ['llama3', MetaLlamaIcon],
  ['stable', StableDiffusionIcon],
  ['mistral', MistralIcon],
  ['mixtral', MistralIcon],
  ['gpt', OpenAIIcon],
  ['o1', OpenAIIcon],
  ['openai', OpenAIIcon],
  ['gemini', GeminiIcon],
]);

const ModelIcon: FC<Props> = ({ iconId, size = 'medium' }) => {
  let compIconId = iconId;

  if (!ModelIconMap.has(compIconId) && iconId.includes('.')) {
    compIconId = iconId.split('.').shift() as ModelIconId;
  }

  if (!ModelIconMap.has(compIconId)) {
    console.warn('unknown model icon', iconId);
    return <Icon name="model-training" />;
  }

  return <img src={ModelIconMap.get(compIconId)} alt={iconId} width={size === 'small' ? 20 : 60} />;
};

export default ModelIcon;
