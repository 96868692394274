import NoneChip from '@/components/chip/none-chip';
import StatusChip, { ChipStatus } from '@/components/chip/status-chip';
import ReadonlyView from '@/components/form/readonly-view';
import { ReadonlyField } from '@/components/form/readonly-view/helpers';
import InlineToast from '@/components/toasts/inline';
import { getNiceDate, JsonObject } from '@/lib/helpers';
import { FC, useMemo } from 'react';
import { TelemetryModel } from '@/lib/models/telemetry/telemetry.model';
import { getTypedTelemetry, TelemetryActionTypes } from '@/lib/models/telemetry';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import Chip from '@/components/chip';
import { Stack } from '@mui/material';

interface Props {
  telemetry: TelemetryModel;
  connector?: ConnectorModel;
}

const TelemetryTypeLabels: Record<string, string> = {
  userEvents: 'User Events',
};

const TelemetryReadonlyView: FC<Props> = ({ connector, telemetry }) => {
  const [data, fields] = useMemo(() => {
    const fields: ReadonlyField[] = [
      { label: 'Name', prop: 'name' },
      { label: 'Type', prop: 'type' },
      { label: 'Activation', prop: 'enabled' },
      { label: 'Status', prop: 'status' },
      { label: 'Status Message', prop: 'statusMessage' },
      { label: 'Connector', prop: 'connector' },
      { label: 'Telemetry Types', prop: 'telemetryTypes' },
      { label: 'Last Synced Date', prop: 'lastSyncedAt' },
      { label: 'Configuration', prop: 'config-section', propType: 'section' },
    ];

    const typedTelemetry = getTypedTelemetry(telemetry);
    const {
      displayName,
      typeLabel,
      enabled,
      status,
      statusMessage,
      telemetryTypes,
      lastSyncedAt,
      config,
    } = typedTelemetry;

    const enabledTelemetryTypes = Object.keys(telemetryTypes).filter(
      (key) => !!telemetryTypes[key as keyof TelemetryActionTypes]
    );

    const data: JsonObject = {
      name: displayName,
      type: typeLabel,
      status: <StatusChip value={status as ChipStatus} size="small" />,
      enabled: <StatusChip value={enabled ? 'enabled' : 'disabled'} size="small" />,
      connector: connector?.name || <NoneChip notAvailable />,
      telemetryTypes: !enabledTelemetryTypes.length ? (
        <NoneChip />
      ) : (
        <Stack direction="row" gap={1}>
          {enabledTelemetryTypes.map((key) => (
            <Chip label={TelemetryTypeLabels[key]} size="small" key={key} />
          ))}
        </Stack>
      ),
      statusMessage: statusMessage ? (
        <InlineToast hideIcon message={statusMessage} level="error" />
      ) : (
        <NoneChip />
      ),
      lastSyncedAt: lastSyncedAt ? getNiceDate(lastSyncedAt).formatted : <NoneChip notAvailable />,
    };

    switch (telemetry.type) {
      case 's3':
        {
          const { bucketName, bucketRegion } = config;

          fields.push({ label: 'AWS Bucket Name', prop: 'bucketName' });
          data.bucketName = bucketName;

          fields.push({ label: 'AWS Region', prop: 'bucketRegion' });
          data.bucketRegion = bucketRegion;
        }
        break;
    }

    return [data, fields];
  }, [connector, telemetry]);

  return <ReadonlyView fields={fields} data={data} />;
};

export default TelemetryReadonlyView;
