import { object, string } from 'yup';
import { IdentProviderModel } from './ident-provider.model';
import { JsonObject } from '@/lib/helpers';
import OIDCIcon from '@/assets/ident-icons/oidc-icon.png';

export type OidcIdentConfig = {
  knownIdpProvider: string;
  clientId: string;
  clientSecret: string;
  issuer: string;
  callbackUrl: string;
};

export class OidcIdentProviderModel extends IdentProviderModel {
  identityProviderConfig: OidcIdentConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    this.schema = this.schema.concat(
      object({
        identityProviderConfig: object({
          clientId: string().required('Client id is required'),
          clientSecret: string().required('Client secret is required'),
          issuer: string().url('Please enter a valid URL').required('Issuer URL is required'),
        }),
      })
    );

    const data = props as Record<keyof OidcIdentProviderModel, unknown>;

    this.type = 'OIDC';
    this.typeLabel = 'OIDC';
    this.iconSVG = OIDCIcon;
    this.canWrite = true;

    this.identityProviderConfig = (data.identityProviderConfig as OidcIdentConfig) || {
      knownIdpProvider: null,
      clientId: '',
      clientSecret: '',
      issuer: '',
      callbackUrl: '',
    };
  }
}
