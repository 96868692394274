import { object, string } from 'yup';
import {
  ContextProviderConfig,
  ContextProviderMeta,
  ContextProviderScanSchedule,
  ContextProviderSource,
  ContextProviderStatus,
  ContextProviderType,
} from '.';
import { JsonObject } from '../../helpers';
import { BaseModel, CreateBaseModel } from '../base.model';

export class ContextProviderModel extends BaseModel {
  orgId: string;
  connectorId: string;
  name: string;
  description?: string;
  type: ContextProviderType;
  typeLabel: string;
  source: ContextProviderSource;
  scanSchedule: ContextProviderScanSchedule;
  status: ContextProviderStatus;
  config: ContextProviderConfig;
  metrics: Record<string, number | string>;
  scanable: boolean;
  comingSoon: boolean;

  schema = object({
    name: string().required('Name is required'),
    type: string().required('Please choose a type'),
  });

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ContextProviderModel, unknown>;

    this.orgId = (data.orgId as string) || '';
    this.connectorId = (data.connectorId as string) || '';
    this.name = (data.name as string) || '';

    if (data.description) {
      this.description = (data.description as string) || '';
    }

    this.type = (data.type as ContextProviderType) || 'unknown';
    this.typeLabel = (data.typeLabel as string) || 'Unknown';
    this.source = (data.type as ContextProviderSource) || 'unknown';
    this.scanSchedule = (data.scanSchedule as ContextProviderScanSchedule) || 'daily';
    this.status = (data.status as ContextProviderStatus) || 'unknown';
    this.config = (data.config || {}) as ContextProviderConfig;
    this.metrics = (data.metrics || {}) as Record<string, number | string>;
    this.scanable = true;
    this.comingSoon = false;
  }

  get meta(): ContextProviderMeta {
    return {
      id: this.id || '',
      name: this.name || '',
    };
  }
}

export class CreateContextProviderModel extends CreateBaseModel {
  name: string;
  description?: string;
  owner: string;
  scanSchedule: ContextProviderScanSchedule;
  source: string;
  connectorId: string;
  config: ContextProviderConfig;

  constructor(provider: ContextProviderModel) {
    super();

    this.name = provider.name;

    if (provider.description) {
      this.description = provider.description;
    }

    this.owner = 'surepath';
    this.scanSchedule = provider.scanSchedule;
    this.source = provider.source;
    this.connectorId = provider.connectorId;

    this.config = provider.config;
  }
}
