import InputControl from '@/components/form/input-control';
import TextInput from '@/components/form/text-input';
import Text from '@/components/text';
import { FormHandle } from '@/hooks/use-form-handle.hook';
import { getTypedIdentProvider } from '@/lib/models/ident-provider';
import { IdentProviderModel } from '@/lib/models/ident-provider/ident-provider.model';
import { getAuthCallbackUrl } from '@/lib/services/auth.service';
import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  formHandle: FormHandle<IdentProviderModel>;
  provider: IdentProviderModel;
}

const useStyles = createUseStyles({
  oidcForm: {
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
});

const OidcIdentProviderForm: FC<Props> = ({ formHandle }) => {
  const styles = useStyles();
  const { values } = formHandle;
  const typedProvider = getTypedIdentProvider(values);

  const hasCallbackURI = !!typedProvider.identityProviderConfig.callbackUrl;
  const callbackURI = hasCallbackURI
    ? typedProvider.identityProviderConfig.callbackUrl
    : getAuthCallbackUrl();

  return (
    <Stack gap={2} className={styles.oidcForm}>
      <Box>
        <Box mb={1}>
          <Text bold>Authorization Callback URL</Text>
        </Box>
        <TextInput name="authCallbackURI" value={callbackURI} copyClip size="small" readonly />
      </Box>

      <Box>
        <InputControl
          name="identityProviderConfig.clientId"
          label="Client Id"
          formHandle={formHandle}
        />
      </Box>

      <Box>
        <InputControl
          name="identityProviderConfig.clientSecret"
          label="Client Secret"
          formHandle={formHandle}
        />
      </Box>

      <Box>
        <InputControl
          name="identityProviderConfig.issuer"
          label="Issuer URL"
          formHandle={formHandle}
        />
      </Box>
    </Stack>
  );
};

export default OidcIdentProviderForm;
