import { FC, useState } from 'react';
import { Box, Grid } from '@mui/material';
import FormSection from '@/components/form/form-section';
import { LoadState } from '@/lib/helpers';
import Button from '@/components/button';
import { connectWorkOS } from '@/lib/services/workos.service';
import { OrgModel } from '@/lib/models/org.model';
import Tooltip from '@/components/tooltip';
import InlineToast from '@/components/toasts/inline';
import { openNewTab } from '@/lib/url-helpers';
import useToast from '@/hooks/use-toast.hook';
import useFeature from '@/hooks/use-feature';
import { useQuery } from 'react-query';
import useQueryHelper from '@/hooks/use-query-helper';
import { getOrg } from '@/lib/services/org.service';
import { QueryKey } from '@/lib/query-client';

const WorkosForm: FC = () => {
  const [loadState, setLoadState] = useState<LoadState>('unloaded');
  const { errorToast } = useToast();

  const query = useQuery([QueryKey.OrgView], async () => getOrg());
  const { showLoader } = useQueryHelper(query);
  const { data: organization } = query;

  const { canChangeSync, getTooltip } = useFeature();

  const handleConnectWorkOS = async (org: OrgModel) => {
    if (loadState !== 'unloaded') {
      return;
    }
    setLoadState('loading');

    const { name, domains } = org;
    const workOSLink = await connectWorkOS({ name, domains });

    setLoadState('unloaded');

    if (!workOSLink) {
      errorToast('Unable to connect to sync service');
      return;
    }

    openNewTab(workOSLink);
  };

  const organizationConnected = !!organization?.externalReferences?.workos?.workosOrganizationId;

  const readonly = !canChangeSync;

  if (showLoader) {
    return null;
  }

  return (
    <Box>
      <FormSection title="Directory Sync">
        <>
          <Grid container marginBottom={2}>
            <Grid item xs={12}>
              Manage your organization's users/groups connection.
            </Grid>
          </Grid>
          <Grid container marginBottom={2} spacing={0}>
            <Grid item xs={12}>
              Status: {organizationConnected ? 'Connected' : 'Not Connected'}
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Tooltip disabled={canChangeSync} title={getTooltip('change-sync')}>
                <Button
                  size="large"
                  onClick={() => {
                    handleConnectWorkOS(organization!);
                  }}
                  fullWidth
                  label="Manage"
                  disabled={readonly}
                  loading={loadState === 'loading'}
                />
              </Tooltip>
            </Grid>
          </Grid>
          {canChangeSync && (
            <Box mt={4}>
              <InlineToast
                level="info"
                message="Changes to users/groups or the connection can take up to two hours to take effect."
              />
            </Box>
          )}
        </>
      </FormSection>
    </Box>
  );
};

export default WorkosForm;
