import { PrivateModelBaseConfig } from '.';
import { JsonObject } from '../../helpers';
import { PrivateModelModel } from './private-model.model';

export type AwsPrivateModelConfig = PrivateModelBaseConfig & {
  region: string;
};

// default to us-east-1 for now since it's the only region bedrock regularly works in
const DEFAULT_AWS_REGION = 'us-east-1';

export class AwsPrivateModelModel extends PrivateModelModel {
  providerModelProperties: AwsPrivateModelConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof PrivateModelModel, unknown>;

    this.type = 'aws';

    this.providerModelProperties = (data.providerModelProperties as AwsPrivateModelConfig) || {
      model: '',
      modelId: '',
      region: DEFAULT_AWS_REGION,
    };
  }
}
