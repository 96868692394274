import { JsonObject } from '../../helpers';
import { ConnectorFeatureSet } from './';
import GeminiIcon from '@/assets/connector-icons/google-gemini.png';
import { ConnectorModel } from './connector.model';

export type GeminiConfig = {
  apiKey: string;
};

export class GeminiConnectorModel extends ConnectorModel {
  config: GeminiConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ConnectorModel, unknown>;

    this.type = 'gemini';
    this.typeLabel = 'Google Gemini';
    this.iconSVG = GeminiIcon;

    this.supportedFeatures = (data.supportedFeatures as ConnectorFeatureSet) || {
      siem: false,
      privateModels: true,
      contextData: false,
      ingress: false,
    };

    // the only config prop is apiKey, and that is kept in secret storage, so no need to load from props
    this.config = {
      apiKey: '',
    };

    this.configSecrets = ['apiKey'];
  }
}
