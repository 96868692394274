import { IdentProviderModel } from './ident-provider.model';
import { JsonObject } from '@/lib/helpers';

export class PasswordIdentProviderModel extends IdentProviderModel {
  constructor(props: JsonObject = {}) {
    super(props);

    this.name = 'Email and Password';
    this.type = 'password';
    this.typeLabel = 'Password';
    this.icon = 'password';
    this.canWrite = false;
  }
}
