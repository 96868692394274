import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FC } from 'react';
import InputControl from '@/components/form/input-control';
import { ViewState } from '@/lib/helpers';
import { Box } from '@mui/material';
import { AwsS3TelemetryModel } from '@/lib/models/telemetry/aws-s3-telemetry.model';
import { TelemetryModel } from '@/lib/models/telemetry/telemetry.model';

interface Props {
  telemetry: AwsS3TelemetryModel;
  formHandle: FormHandle<TelemetryModel>;
  mode: ViewState;
}

const AwsS3Telemetry: FC<Props> = ({ formHandle, mode }) => {
  const inWriteMode = ['edit', 'add'].includes(mode);

  if (!inWriteMode) {
    return null;
  }

  return (
    <Box>
      <InputControl
        name="config.bucketRegion"
        label="AWS Region"
        formHandle={formHandle}
        type="aws-region-select"
      />

      <InputControl name="config.bucketName" label="AWS Bucket Name" formHandle={formHandle} />
    </Box>
  );
};

export default AwsS3Telemetry;
