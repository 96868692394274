import { FormHandle } from '@/hooks/use-form-handle.hook';
import { FC } from 'react';
import InputControl from '@/components/form/input-control';
import { ViewState } from '@/lib/helpers';
import { Box } from '@mui/material';
import { ConnectorModel } from '@/lib/models/connector/connector.model';

interface Props {
  connector: ConnectorModel;
  formHandle: FormHandle<ConnectorModel>;
  mode: ViewState;
}

const ApiKeyConnector: FC<Props> = ({ formHandle, mode, connector }) => {
  const inWriteMode = ['edit', 'add'].includes(mode);
  const isNew = !connector.id;

  return (
    <>
      {inWriteMode && (
        <Box>
          <InputControl
            name="config.apiKey"
            label="API Key"
            formHandle={formHandle}
            updateOnly={!isNew}
          />
        </Box>
      )}
    </>
  );
};

export default ApiKeyConnector;
