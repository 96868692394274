import { object, string, array } from 'yup';
import { BaseModel, CreateBaseModel } from './base.model';
import { JsonObject } from '../helpers';
import * as yup from 'yup';
import { IdentProviderModel } from './ident-provider/ident-provider.model';
import { has, upperCase } from 'lodash';

export type OrgMode = 'discovery' | 'trial' | 'platform';

export type OrgBasicInfo = {
  name: string;
  mode: OrgMode;
};

type OrgExternalReferences = {
  workos?: {
    workosOrganizationId?: string;
  };
};

type OrgIdentityConfig = {
  gcipTenantId: string;
  providers: IdentProviderModel[]; // @todo add other provider types
};

export type OrgBranding = {
  lightThemeLogo: string;
  portalBadge: {
    image: string;
    title: string;
  };
  portalWelcome: {
    title: string;
    message: string;
    learnMoreLink: string;
  };
  supportEmail: string;
  favicon: string;
  warnings: {
    publicServiceBlocked: string;
    publicServiceSensitiveData: string;
    privateServiceIntentBlocked: string;
  };
};

export class OrgModel extends BaseModel {
  name: string;
  domains: string[];
  allowSupportAccess: boolean;
  identityConfig: OrgIdentityConfig;
  externalReferences?: OrgExternalReferences;
  branding: OrgBranding;
  mode: OrgMode;
  disablePromptRetention: boolean;

  schema = object({
    name: string().required('Organization Name is required'),
    domains: array().of(yup.string()).min(1, 'Your organization must have at least one domain'),
    branding: object({
      portalWelcome: object({
        message: string().max(1024, 'The welcome message must be no more than 1,024 characters'),
      }),
    }),
  });

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof OrgModel, unknown>;

    this.name = (data.name as string) || '';
    this.domains = (data.domains as string[]) || [];

    this.allowSupportAccess = Boolean(data.allowSupportAccess);
    this.identityConfig = {
      gcipTenantId: '',
      providers: [],
    };
    this.externalReferences = data.externalReferences as OrgExternalReferences;

    if (data.identityConfig) {
      this.identityConfig = data.identityConfig as OrgIdentityConfig;
      this.identityConfig.providers = this.identityConfig.providers.map(
        (data) => new IdentProviderModel(data as unknown as JsonObject)
      );
    }

    this.branding = (data.branding as OrgBranding) || {
      lightThemeLogo: '',
      portalBadge: {
        image: '',
        title: '',
      },
      portalWelcome: {
        title: '',
        message: '',
        learnMoreLink: '',
      },
      supportEmail: '',
      favicon: '',
      warnings: {
        publicServiceBlocked: '',
        publicServiceSensitiveData: '',
        privateServiceIntentBlocked: '',
      },
    };

    this.mode = (data.mode as OrgMode) || 'platform';
    this.disablePromptRetention = !!data.disablePromptRetention;

    // legacy trial flag support
    if (!has(data, 'mode')) {
      const oldOrgData = data as JsonObject;
      if ((oldOrgData.trial as JsonObject)?.enabled || oldOrgData.isTrial) {
        this.mode = 'trial';
      }
    }
  }

  get hasOIDC(): boolean {
    return this.identityConfig.providers.some(({ type }) => type === 'OIDC');
  }

  get hasSAML(): boolean {
    return this.identityConfig.providers.some(({ type }) => type === 'SAML');
  }

  get basicInfo(): OrgBasicInfo {
    return {
      name: this.name,
      mode: this.mode,
    };
  }

  get modeLabel(): string {
    return upperCase(this.mode || 'platform');
  }
}

export class CreateOrgModel extends CreateBaseModel {
  allowSupportAccess: boolean;
  branding: OrgBranding;

  constructor(org: OrgModel) {
    super();
    this.allowSupportAccess = org.allowSupportAccess;
    this.branding = org.branding;
  }
}
