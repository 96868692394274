import Text from '@/components/text';
import { Box, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import AddCard from './add-card';
import { GridCardCategory, GridCard as GridCardProps } from './helpers';
import GridCard from './grid-card';
import EmptyCard from './empty-card';
import { SortButton } from '../button-bar/helpers';
import ButtonBar from '../button-bar';
import Icon from '../icon';

const useStyles = createUseStyles((theme: Theme) => ({
  cardGridHeader: {
    padding: '0px 0px 8px 0px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: 24,
  },
}));

interface Props {
  title?: string;
  onAdd?: () => void;
  cards: GridCardProps[];
  categories?: GridCardCategory[];
  canAdd?: boolean;
  showAdd?: boolean;
  addTitle?: string;
  addDisabledTooltip?: string;
  showEmpty?: boolean;
  onSort?: (sortType: string) => void;
  onRefresh?: () => void;
  sortButtons?: SortButton[];
  sortType?: string;
}

const CardGrid: FC<Props> = ({
  title,
  onAdd,
  canAdd = true,
  showAdd = true,
  cards,
  addTitle = '',
  addDisabledTooltip,
  categories = [],
  showEmpty = false,
  onSort,
  onRefresh,
  sortButtons = [],
  sortType: activeSort,
}) => {
  const styles = useStyles();

  const hasTitle = !!title;
  const hasCategories = !!categories.length;
  const showEmptyCard = !hasCategories && showEmpty && !cards.length;
  const hasSort = !!sortButtons.length;

  const handleAdd = () => {
    onAdd?.();
  };

  const handleRefresh = () => {
    onRefresh?.();
  };

  const handleSort = (sortType: string) => {
    onSort?.(sortType);
  };

  const hasRefresh = !!onRefresh;

  return (
    <Box width="100%" pb={2}>
      {hasTitle && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          className={styles.cardGridHeader}
          width="100%"
        >
          <Box>
            <Text size="large">{title}</Text>
          </Box>
          <Stack flexGrow={1} direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
            {hasRefresh && <Icon name="refresh" onClick={handleRefresh} spinOnClick />}
            {hasSort && (
              <ButtonBar onSort={handleSort} sortType={activeSort} buttons={sortButtons} />
            )}
          </Stack>
        </Stack>
      )}
      <Stack gap={2} flexWrap="wrap" flexDirection="row" width="100%">
        {hasCategories &&
          categories.map(({ title, id: categoryId }) => {
            const categoryCards = cards.filter(
              ({ categoryId: cardCatId }) => cardCatId === categoryId
            );

            const showEmptyCat = showEmpty && !categoryCards.length;

            return (
              <Box key={categoryId} width="100%" mb={2}>
                <Box mb={2}>
                  <Text size="large" color="grey">
                    {title}
                  </Text>
                </Box>
                <Stack gap={2} flexWrap="wrap" flexDirection="row">
                  {categoryCards.map((gridCard) => {
                    const { id } = gridCard;
                    return <GridCard gridCard={gridCard} key={id} />;
                  })}
                </Stack>
                {showEmptyCat && <EmptyCard />}
              </Box>
            );
          })}
        {!hasCategories &&
          cards.map((gridCard) => {
            const { id } = gridCard;
            return <GridCard gridCard={gridCard} key={id} />;
          })}

        {showEmptyCard && <EmptyCard />}

        {showAdd && (
          <AddCard
            title={addTitle}
            disabled={!canAdd}
            onClick={handleAdd}
            disabledTooltip={addDisabledTooltip}
          />
        )}
      </Stack>
    </Box>
  );
};

export default CardGrid;
