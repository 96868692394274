import InputControl from '@/components/form/input-control';
import { FormHandle } from '@/hooks/use-form-handle.hook';
import { IdentProviderModel } from '@/lib/models/ident-provider/ident-provider.model';
import { SamlMetadataUrlIdentProviderModel } from '@/lib/models/ident-provider/saml-metadata-url-ident-provider.model';
import { Stack } from '@mui/material';
import { FC } from 'react';

interface Props {
  formHandle: FormHandle<IdentProviderModel>;
  provider: SamlMetadataUrlIdentProviderModel;
}

const SamlXmlIdentProviderForm: FC<Props> = ({ formHandle }) => {
  return (
    <Stack gap={2}>
      <InputControl
        name="identityProviderConfig.samlMetadataXML"
        label="Metadata XML"
        formHandle={formHandle}
        type="textarea"
        rows={10}
      />
    </Stack>
  );
};

export default SamlXmlIdentProviderForm;
