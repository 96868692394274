import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Drawer, { DrawerProps } from '..';
import DrawerHeader from '../header';
import { Box } from '@mui/material';
import DrawerContent from '../content';
import { useQuery } from 'react-query';
import { pluralize, ViewState } from '@/lib/helpers';
import useQueryHelper from '@/hooks/use-query-helper';
import useFormHandle from '@/hooks/use-form-handle.hook';
import {
  ConnectorType,
  ConnectorMap,
  getTypedConnector,
  getConnectorCategoryOptions,
} from '@/lib/models/connector';
import InputControl from '../../form/input-control';
import useToast from '@/hooks/use-toast.hook';
import useModal from '@/hooks/use-modal.hook';
import DrawerButtons from '@/components/form/drawer-buttons';
import {
  createConnector,
  deleteConnector,
  getConnectorById,
  getConnectorsByType,
  getConnectorUsage,
  maskConnectorStatus,
  patchConnector,
} from '@/lib/services/connector.service';

import { CreateResponse, PatchResponse } from '@/lib/services';
import AwsConnector from './aws-connector';
import ConnectorReadonlyView from './readonly-view';
import SharepointConnector from './sharepoint-connector';
import DrawerToolbar from '../toolbar';
import Select from '@/components/form/select';
import { AwsConnectorModel } from '@/lib/models/connector/aws-connector.model';
import { SharepointConnectorModel } from '@/lib/models/connector/sharepoint-connector.model';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import { PrivateModelModel } from '@/lib/models/private-model/private-model.model';
import { ContextProviderModel } from '@/lib/models/context-provider/context-provider.model';
import InlineToast from '@/components/toasts/inline';
import WebcrawlerConnector from './webcrawler-connector';
import { WebcralwerConnectorModel } from '@/lib/models/connector/webcrawler-connector.model';
import useFeature from '@/hooks/use-feature';
import NetskopeConnector from './netskope-connector';
import { NetskopeConnectorModel } from '@/lib/models/connector/netskope-connector.model';
import ZscalerConnector from './zscaler-connector';
import { ZscalerConnectorModel } from '@/lib/models/connector/zscaler-connector.model';
import ApiKeyConnector from './api-key-connector';
import AzureOpenAIConnector from './azure-openai-connector';
import { AzureOpenAIConnectorModel } from '@/lib/models/connector/azure-openai-connector.model';

interface Props extends DrawerProps {
  id: string | null;
  connectorType?: ConnectorType;
  name?: string;
}

export const ConnectorDrawer: FC<Props> = ({
  id,
  open,
  onClose,
  onChange,
  className,
  connectorType: propConnectorType,
  name: propName = '',
}) => {
  const [connectorId, setConnectorId] = useState(id || null);
  const [connectorType, setConnectorType] = useState<ConnectorType | ''>(propConnectorType || '');

  const formModel = useMemo(() => {
    const connector = new ConnectorModel({ type: propConnectorType, name: propName });
    return getTypedConnector(connector);
  }, [propName, propConnectorType]);

  const isNew = !connectorId;
  const [mode, setMode] = useState<ViewState>(isNew ? 'add' : 'view');

  const { toast, errorToast } = useToast();
  const { openModal } = useModal();
  const { canChangeConnector, canViewConnectorArn, ragStatus } = useFeature();

  const query = useQuery<[ConnectorModel | null, PrivateModelModel[], ContextProviderModel[]]>(
    [QueryKey.ConnectorMetaView, connectorId],
    async () => {
      const connector = await getConnectorById(connectorId!);

      if (!connector) {
        return [null, [], []];
      }

      const [contexts, models] = await getConnectorUsage(connector.id);

      return [connector, models, contexts];
    },
    {
      enabled: !isNew,
    }
  );

  const { showLoader: connectorLoading } = useQueryHelper(query);
  const { data, refetch } = query;

  const conflictQuery = useQuery(
    [QueryKey.ConnectorsTypeList, connectorType, mode],
    async () => {
      if (mode !== 'add') {
        return false;
      }

      if (!connectorType) {
        return false;
      }

      const typedConnector = ConnectorMap.get(connectorType);

      if (!typedConnector?.highlander) {
        return false;
      }

      const existConnectors = await getConnectorsByType(connectorType);

      return !!existConnectors?.length;
    },
    { enabled: !!connectorType && mode === 'add' }
  );

  const { data: hasConflict } = conflictQuery;
  const { showLoader: conflictLoading } = useQueryHelper(conflictQuery);

  const [connectorData, models, contexts] = data || [null, [], []];
  const [connector] = maskConnectorStatus(
    [connectorData || new ConnectorModel({ type: propConnectorType })],
    ragStatus
  );
  const canDelete = !models.length && !contexts.length;
  const canChange = canChangeConnector(connector);

  // you can only create new connectors if you have access to that connector type
  const safeConnectorOptions = useMemo(() => {
    return getConnectorCategoryOptions().map((opt) => {
      const { value, disabled } = opt;

      if (!disabled) {
        const connector = ConnectorMap.get(value as ConnectorType);
        opt.disabled = !canChangeConnector(connector);
      }
      return opt;
    });
  }, [canChangeConnector]);

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schema,
    validateOnChange: !isNew,
    onSubmit: async (connector, { setFieldError }) => {
      const handleErrors = (response: CreateResponse | PatchResponse): boolean => {
        const { error, fieldErrors } = response;

        if (error) {
          errorToast(error);
          return false;
        }

        if (fieldErrors) {
          fieldErrors.forEach(({ path, msg }) => {
            let fieldName = path;
            if (fieldName in connector.config) {
              fieldName = `config.${fieldName}`;
            }
            if (
              isNew &&
              path === 'crossAccountRoleARN' &&
              !fieldErrors.some(({ path }) => path === 'awsAccountId')
            ) {
              fieldName = 'defaultRoleARN';
            }
            setFieldError(fieldName, msg);
          });
          return false;
        }

        return true;
      };

      if (!connectorType) {
        return;
      }

      connector.type = connectorType;

      if (!connectorId) {
        const response = await createConnector(connector);

        if (!handleErrors(response)) {
          return;
        }

        const newConnector = response.model! as ConnectorModel;

        toast('The connector was created');
        setConnectorId(newConnector.id);
        setConnectorType(newConnector.type);
        onChange && onChange();
        setMode('view');
        return;
      }

      const response = await patchConnector(connector);

      if (!handleErrors(response)) {
        return;
      }

      toast('The connector was updated');
      refetch();
      onChange && onChange();
      setMode('view');
    },
  });

  const { handleSubmit, handleBlur, canSubmit, isSubmitting, loadData, setValues, values } =
    formHandle;

  const inWriteMode = ['edit', 'add'].includes(mode);

  const handleCancel = () => {
    if (isNew) {
      onClose();
      return;
    }
    setMode('view');
    loadData(connector);
  };

  const handleEdit = () => {
    setMode('edit');
  };

  const handleChangeConnectorType = useCallback(
    (value: string) => {
      if (!isNew) {
        return;
      }

      const connectorType = value as ConnectorType;
      setConnectorType(connectorType);
      values.type = connectorType;
      setValues(getTypedConnector(values));
    },
    [setValues, values, isNew]
  );

  const handleDelete = () => {
    const hasModels = !!models.length;
    const hasContext = !!contexts.length;

    openModal('confirm', {
      title: 'Confirm Delete',
      content: (
        <>
          Are you sure you want to delete {connector.name}?
          {hasModels && (
            <Box mt={2}>
              <InlineToast
                message={`${models.length} ${pluralize(
                  models.length,
                  'Private Model'
                )} will be disconnected`}
                level="warning"
              />
            </Box>
          )}
          {hasContext && (
            <Box mt={2}>
              <InlineToast
                message={`${contexts.length} ${pluralize(
                  contexts.length,
                  'Data Sources'
                )} will be disconnected`}
                level="warning"
              />
            </Box>
          )}
        </>
      ),
      onClose: (confirm: boolean) => {
        if (confirm) {
          deleteConnector(connector).then(({ deleted, error }) => {
            if (!deleted) {
              errorToast(error || 'The connector was not deleted');
              return;
            }

            setConnectorId('');
            setConnectorType('');
            onClose();
            toast('The connector was deleted');
            onChange && onChange();
          });
        }
      },
    });
  };

  useEffect(() => {
    if (data?.length) {
      const [connector] = data;
      if (connector) {
        loadData(getTypedConnector(connector));
        setConnectorId(connector.id);
        setConnectorType(connector.type);
      }
    }
  }, [data, loadData]);

  const { name } = connector;
  const showChildren = !connectorLoading;

  const typedConnector = connectorType ? ConnectorMap.get(connectorType) : null;
  const connectorTypeLabel = typedConnector?.typeLabel || '';
  const comingSoon = !!typedConnector?.comingSoon;

  return (
    <Drawer open={open} onClose={onClose} className={className} query={query}>
      <DrawerHeader onClose={onClose}>{isNew ? 'Add Connector' : name}</DrawerHeader>
      <DrawerContent>
        {!isNew && canChange && !comingSoon && (
          <DrawerToolbar
            onEdit={handleEdit}
            onDelete={handleDelete}
            canEdit={!inWriteMode}
            canDelete={canDelete}
            deleteTooltip={canDelete ? '' : 'You cannot delete a connector that is in use'}
          />
        )}
        {!inWriteMode && showChildren && (
          <ConnectorReadonlyView
            connector={connector}
            canViewArn={canViewConnectorArn}
            privateModels={models}
            contextProviders={contexts}
          />
        )}

        {showChildren && (
          <Form formHandle={formHandle}>
            {inWriteMode && (
              <>
                <InputControl name="name" label="Name" formHandle={formHandle} autoFocus />

                <Select
                  name="type"
                  value={connectorType}
                  label="Type"
                  options={safeConnectorOptions}
                  readonly={!isNew}
                  onBlur={handleBlur}
                  onChange={handleChangeConnectorType}
                />
              </>
            )}

            {hasConflict && (
              <InlineToast
                level="warning"
                message={`There can be only one active ${connectorTypeLabel} connector. Please remove the existing connector before adding a new one.`}
              />
            )}

            {!hasConflict && !conflictLoading && (
              <>
                {connectorType === 'aws' && (
                  <AwsConnector
                    formHandle={formHandle}
                    connector={connector as AwsConnectorModel}
                    mode={mode}
                  />
                )}

                {connectorType === 'sharepoint-online' && (
                  <SharepointConnector
                    formHandle={formHandle}
                    connector={connector as SharepointConnectorModel}
                    mode={mode}
                  />
                )}

                {connectorType === 'webcrawler' && (
                  <WebcrawlerConnector
                    formHandle={formHandle}
                    connector={connector as WebcralwerConnectorModel}
                    mode={mode}
                  />
                )}

                {connectorType === 'netskope' && (
                  <NetskopeConnector
                    formHandle={formHandle}
                    connector={connector as NetskopeConnectorModel}
                    mode={mode}
                  />
                )}

                {connectorType === 'zscaler' && (
                  <ZscalerConnector
                    formHandle={formHandle}
                    connector={connector as ZscalerConnectorModel}
                    mode={mode}
                  />
                )}

                {['openai', 'gemini', 'brave-search'].includes(connectorType) && (
                  <ApiKeyConnector formHandle={formHandle} connector={connector} mode={mode} />
                )}

                {connectorType === 'azure-openai' && (
                  <AzureOpenAIConnector
                    formHandle={formHandle}
                    connector={connector as AzureOpenAIConnectorModel}
                    mode={mode}
                  />
                )}
              </>
            )}

            {inWriteMode && (
              <DrawerButtons
                submit
                cancel
                isNew={isNew}
                canSubmit={canSubmit}
                canCancel={!isSubmitting}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                readonly={hasConflict}
              />
            )}
          </Form>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ConnectorDrawer;
