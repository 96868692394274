import { Dataset, DtFilter, DtSort } from '@/components/data-table/helpers';
import { JsonObject } from '../helpers';
import { create, deleteApi, get, patch, post } from './sp-api.service';
import { CreateTelemetryModel, TelemetryModel } from '../models/telemetry/telemetry.model';
import { CreateResponse, DEFAULT_SERVICE_ERROR, DeleteResponse, PatchResponse } from '.';
import { AxiosError } from 'axios';
import { orderBy } from 'lodash';

type TelemetryListResponse = {
  count: number;
  currentPage: number;
  data: JsonObject[];
  hasNext: boolean;
  hasPrevious: boolean;
  pageSize: number;
  totalPages: number;
};

export type TelemetryTestReport = {
  accessVerified: boolean;
  found: boolean;
  canWrite: boolean;
  messages: string[];
};

export const getTelemetry = async (
  page: number,
  pageSize: number,
  dtSort?: DtSort,
  dtFilter?: DtFilter
): Promise<Dataset<TelemetryModel>> => {
  const result = (await get('/telemetry/telemetry-destinations', {
    page: 1,
    pageSize: 100,
  })) as TelemetryListResponse;

  if (!Array.isArray(result?.data)) {
    return {
      page,
      pageSize,
      rows: [],
      total: 0,
    };
  }

  let rows: TelemetryModel[] = result.data.map((data) => new TelemetryModel(data));

  if (dtFilter?.keyword) {
    const safeKeyword = String(dtFilter?.keyword).toLowerCase();
    rows = rows.filter(({ displayName }) => displayName.toLowerCase().includes(safeKeyword));
  }

  // Sort
  if (dtSort) {
    const { columnName, direction } = dtSort;
    rows = orderBy(rows, [columnName], [direction]);
  }

  // Paginate
  const startIndex = page * pageSize;
  rows = rows.slice(startIndex, startIndex + pageSize);

  return {
    page,
    pageSize,
    rows,
    total: result.count,
  };
};

export const createTelemetry = async (telemetry: TelemetryModel): Promise<CreateResponse> => {
  const createModel = new CreateTelemetryModel(telemetry);

  const response = await create(
    '/telemetry/telemetry-destinations',
    createModel._props,
    handleCreateErrorResponse
  );

  if (!response?.created) {
    return response;
  }

  response.model = new TelemetryModel(response.data);

  await testTelemetry(response.model.id);

  return response;
};

export const patchTelemetry = async (telemetry: TelemetryModel): Promise<PatchResponse> => {
  const createModel = new CreateTelemetryModel(telemetry);

  const response = await patch(
    `/telemetry/telemetry-destinations/${telemetry.id}`,
    createModel._props,
    handlePatchErrorResponse
  );

  if (!response?.patched) {
    return response;
  }

  await testTelemetry(telemetry.id);

  return response;
};

export const getTelemetryById = async (telemetryId: string): Promise<TelemetryModel | null> => {
  const response = await get(`/telemetry/telemetry-destinations/${telemetryId}`);
  return response ? new TelemetryModel(response as JsonObject) : null;
};

export const deleteTelemetry = async (telemetryId: string): Promise<DeleteResponse> => {
  return deleteApi(`/telemetry/telemetry-destinations/${telemetryId}`);
};

export const testTelemetry = async (telemetryId: string): Promise<TelemetryTestReport> => {
  const response = (await post(
    `/telemetry/telemetry-destinations/${telemetryId}/verify-access`,
    {}
  )) as { report: TelemetryTestReport };

  return response?.report || { accessVerified: false, found: false, canWrite: false, messages: [] };
};

const handleCreateErrorResponse = (error: AxiosError): CreateResponse => {
  if (!error.response) {
    return { created: false, error: DEFAULT_SERVICE_ERROR };
  }

  const { status, data } = error.response as { status: number; data: JsonObject };

  if (status === 400 && Array.isArray(data?.errors)) {
    return {
      created: false,
      fieldErrors: data?.errors.map(({ field, rule }) => ({
        msg: rule as string,
        path: field as string,
        value: '',
      })),
    };
  }

  return { created: false, error: DEFAULT_SERVICE_ERROR };
};

const handlePatchErrorResponse = (error: AxiosError): PatchResponse => {
  if (!error.response) {
    return { patched: false, error: DEFAULT_SERVICE_ERROR };
  }

  const { status, data } = error.response as { status: number; data: JsonObject };

  if (status === 400 && Array.isArray(data?.errors)) {
    return {
      patched: false,
      fieldErrors: data?.errors.map(({ field, rule }) => ({
        msg: rule as string,
        path: field as string,
        value: '',
      })),
    };
  }

  return { patched: false, error: DEFAULT_SERVICE_ERROR };
};
