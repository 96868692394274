import { Box, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
}

const useStyles = createUseStyles((theme: Theme) => ({
  drawerFooter: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const DrawerFooter: FC<Props> = ({ children }) => {
  const styles = useStyles();
  return (
    <Box className={styles.drawerFooter} mt={4} pt={2}>
      {children}
    </Box>
  );
};

export default DrawerFooter;
