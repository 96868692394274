import { FC, useEffect, useMemo, useState } from 'react';
import Drawer, { DrawerProps } from '..';
import DrawerHeader from '../header';
import DrawerContent from '../content';
import { useQuery } from 'react-query';
import { UiOption, ViewState } from '@/lib/helpers';
import useQueryHelper from '@/hooks/use-query-helper';
import useFormHandle from '@/hooks/use-form-handle.hook';
import { ConnectorType } from '@/lib/models/connector';
import InputControl from '../../form/input-control';
import useToast from '@/hooks/use-toast.hook';
import useModal from '@/hooks/use-modal.hook';
import DrawerButtons from '@/components/form/drawer-buttons';
import { getSiemConnectors } from '@/lib/services/connector.service';
import DrawerToolbar from '../toolbar';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import useFeature from '@/hooks/use-feature';
import { getTelemetryOptions, getTypedTelemetry, TelemetryType } from '@/lib/models/telemetry';
import { TelemetryModel } from '@/lib/models/telemetry/telemetry.model';
import {
  createTelemetry,
  deleteTelemetry,
  getTelemetryById,
  patchTelemetry,
} from '@/lib/services/telemetry.service';
import AwsS3Telemetry from './aws-s3-telemetry';
import { AwsS3TelemetryModel } from '@/lib/models/telemetry/aws-s3-telemetry.model';
import { FieldError } from '@/lib/services';
import TelemetryReadonlyView from './readonly-view';
import TelemetryTestCard from './test-card';
import { Box } from '@mui/material';

interface Props extends DrawerProps {
  id: string | null;
  connectorType?: ConnectorType;
  name?: string;
}

const formModel = new TelemetryModel();

export const TelemetryDrawer: FC<Props> = ({ id, open, onClose, onChange }) => {
  const [telemetryId, setTelemetryId] = useState(id || null);
  const isNew = !telemetryId;
  const [mode, setMode] = useState<ViewState>(isNew ? 'add' : 'view');

  const { toast, errorToast } = useToast();
  const { openModal } = useModal();
  const { canChangeTelemetry } = useFeature();

  const query = useQuery<TelemetryModel | null>(
    [QueryKey.TelemetryView, telemetryId],
    async () => getTelemetryById(telemetryId!),
    {
      enabled: !isNew,
    }
  );

  const { showLoader: telemetryLoading } = useQueryHelper(query);
  const { data: telemetry, refetch } = query;

  const connectorQuery = useQuery([QueryKey.ConnectorSiemsList, id], async () =>
    getSiemConnectors()
  );
  const { showLoader: connectorsLoading } = useQueryHelper(connectorQuery);
  const { data: connectors } = connectorQuery;

  const canDelete = true;

  const formHandle = useFormHandle<TelemetryModel>({
    initialValues: formModel,
    validationSchema: formModel.schema,
    validateOnChange: !isNew,
    onSubmit: async (telemetry, { setFieldError }) => {
      const setFieldErrors = (fieldErrors: FieldError[]) => {
        fieldErrors.forEach(({ msg, path }) => setFieldError(path, msg));
      };

      if (!telemetryId) {
        const { fieldErrors, error, created, model } = await createTelemetry(telemetry);

        if (fieldErrors) {
          setFieldErrors(fieldErrors);
          return;
        }

        if (error || !created) {
          errorToast(error || 'The telemetry destination was not created');
          return;
        }

        setTelemetryId(model?.id || '');
        toast('The telemetry destination was created');
        onChange?.();
        setMode('view');
        return;
      }

      const { patched, fieldErrors, error } = await patchTelemetry(telemetry);

      if (fieldErrors) {
        setFieldErrors(fieldErrors);
        return;
      }

      if (error || !patched) {
        errorToast(error || 'The telemetry destination was not updated');
        return;
      }

      toast('The telemetry destination was updated');
      refetch();
      onChange?.();
      setMode('view');
    },
  });

  const { handleSubmit, handleBlur, canSubmit, isSubmitting, loadData, values } = formHandle;

  const inWriteMode = ['edit', 'add'].includes(mode);
  const telemetryType = values.type;

  const handleCancel = () => {
    if (isNew) {
      onClose();
      return;
    }
    setMode('view');
    loadData(telemetry || new TelemetryModel());
  };

  const handleEdit = () => {
    setMode('edit');
  };

  const handleDelete = () => {
    openModal('confirm', {
      title: 'Confirm Delete',
      content: `Are you sure you want to delete ${telemetry?.displayName}? This action cannot be undone.`,
      onClose: (confirm: boolean) => {
        if (confirm) {
          deleteTelemetry(telemetry!.id).then(({ deleted, error }) => {
            if (!deleted) {
              errorToast(error || 'The telemetry destination was not deleted');
              return;
            }

            setTelemetryId('');
            onClose();
            toast('The telemetry destination was deleted');
            onChange?.();
          });
        }
      },
    });
  };

  const handleTest = () => {
    refetch();
    onChange?.();
  };

  const connectorOpts: UiOption[] = useMemo(
    () =>
      (connectors || []).map(({ id, name, status }) => ({
        label: name,
        value: id,
        disabled: status !== 'active',
      })),
    [connectors]
  );

  const connector = useMemo(
    () => connectors?.find(({ id }) => id === values.connectorId),
    [connectors, values.connectorId]
  );

  const telemetryTypeOpts: UiOption[] = useMemo(() => {
    if (!connector) {
      return [];
    }

    return getTelemetryOptions().filter(({ value }) =>
      connector.telemetryDestinationTypes.includes(value as TelemetryType)
    );
  }, [connector]);

  useEffect(() => {
    if (telemetry) {
      loadData(getTypedTelemetry(telemetry));
      setTelemetryId(telemetry.id);
    }
  }, [telemetry, loadData]);

  const isLoading = telemetryLoading || connectorsLoading;
  const hasTelemetry = !!telemetry?.id;

  return (
    <Drawer open={open} onClose={onClose} loading={isLoading}>
      <DrawerHeader onClose={onClose}>
        {isNew ? 'Add Telemetry Destination' : telemetry?.displayName}
      </DrawerHeader>
      <DrawerContent>
        {!isNew && canChangeTelemetry && (
          <DrawerToolbar
            onEdit={handleEdit}
            onDelete={handleDelete}
            canEdit={!inWriteMode}
            canDelete={canDelete}
            deleteTooltip={
              canDelete ? '' : 'You cannot delete a telemetry destination that is in use'
            }
          />
        )}
        {!inWriteMode && !isLoading && hasTelemetry && (
          <TelemetryReadonlyView connector={connector} telemetry={telemetry} />
        )}

        <Form formHandle={formHandle}>
          {inWriteMode && (
            <>
              <InputControl name="displayName" label="Name" formHandle={formHandle} autoFocus />

              <InputControl
                name="connectorId"
                label="Connector"
                formHandle={formHandle}
                type="select"
                options={connectorOpts}
                readonly={!isNew}
              />

              <InputControl
                name="type"
                label="Type"
                type="select"
                formHandle={formHandle}
                options={telemetryTypeOpts}
                readonly={!isNew || !telemetryTypeOpts.length}
                onBlur={handleBlur}
              />
            </>
          )}

          {telemetryType === 's3' && (
            <AwsS3Telemetry
              formHandle={formHandle}
              telemetry={telemetry as AwsS3TelemetryModel}
              mode={mode}
            />
          )}

          {inWriteMode && (
            <InputControl
              name="enabled"
              label="Enabled"
              type="switch"
              formHandle={formHandle}
              inline={false}
            />
          )}

          {inWriteMode && (
            <DrawerButtons
              submit
              cancel
              isNew={isNew}
              canSubmit={canSubmit}
              canCancel={!isSubmitting}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          )}
        </Form>
        {!isNew && !inWriteMode && (
          <Box mt={2}>
            <TelemetryTestCard telemetryId={telemetryId} onTest={handleTest} />
          </Box>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default TelemetryDrawer;
